/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { withAuthenticator } from "aws-amplify-react";
import {
  Button,
  Header,
  Icon,
  Image,
  Label,
  Table,
  Grid,
  GridRow,
  Form,
  Message,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import DropdownList from "../../components/atoms/DropdownList";
import countries from "../../constants/countries";
import SweetAlert from "react-bootstrap-sweetalert";

import "./styles.css";
import { getAppData } from "../../services/AppData";
import { API_GATEWAY } from "../../constants/Urls";
import axios from "axios";
import { Auth } from "aws-amplify";
import { State, City } from "country-state-city";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";

// Modified By : Sanal
// Modified Date : 20220526
// Subject : Can't filter maids by name
// Commit 2

const moment = require("moment");
const TIMEOUT = 90000;

const statuses = [
  {
    key: "ALL",
    text: "ALL",
    value: "ALL",
  },
  {
    key: "ACTIVE",
    text: "ACTIVE",
    value: "ACTIVE",
  },
  {
    key: "PENDING",
    text: "PENDING",
    value: "PENDING",
  },
  {
    key: "BLOCKED",
    text: "BLOCKED",
    value: "BLOCKED",
  },
];

const Maids = ({ location, history }) => {
  const pagingFromStorage = localStorage.getItem("paging");
  const isFirstPage = location.search === "?p=1";

  const getPagingData =
    pagingFromStorage && !isFirstPage
      ? JSON.parse(pagingFromStorage)
      : {
          tokens: [],
          prev: null,
          next: null,
        };

  const [filterStatus, setStatus] = useState(
    location?.state?.filterby ?? "ALL"
  );

  const [searchCriteria, setSearchCriteria] = useState({ limit: 50 });
  const [paging, setPaging] = useState(getPagingData);
  const [sorting, setSorting] = useState("dateregistered-desc");
  const [locale, setLocale] = useState("en-GB");
  const [cntLoading, setCntLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const defaultPageSize = 15;
  const localStatusIndexName = "locale-status-index";
  const localeDateRegisteredIndexName = "locale-dateRegistered-index";
  const localeUnresponsesIndexName = "locale-unresponses-index";
  const localeEnquiriesIndexName = "locale-enquiries-index";
  const localeUserReviewsIndexName = "locale-userReviews-index";

  const [openMailConfirm, setopenMailConfirm] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [cities, setCities] = useState([]);
  const [displayedCities, setDisplayedCities] = useState([]); // Cities to display in batches
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityValue, setSelectedCityValue] = useState("");
  const [batchSize] = useState(500); // Set batch size

  function searchMaids() {
    setCntLoading(true);
    let vSearcCriteria;
    if (!cntLoading) return;
    if (!searchCriteria || !searchCriteria.useIndex) {
      vSearcCriteria = {
        ...searchCriteria,
        useIndex: localeDateRegisteredIndexName,
        scanIndexForward: false,
      };
    } else {
      vSearcCriteria = searchCriteria;
    }

    const pBody = {
      query: {
        TableName: "maids",
        IndexName: vSearcCriteria.useIndex,
        KeyConditionExpression: "#locale = :vLocale and #stat = :vStatus",
        ExpressionAttributeValues: {},
        ExpressionAttributeNames: null,
        FilterExpression: null,
      },
      limit: defaultPageSize,
      byPage: true,
    };

    if (filterStatus === "ALL") {
      pBody.query.KeyConditionExpression = "#locale = :vLocale";
      pBody.query.ExpressionAttributeValues = {
        ":vLocale": locale,
        ":townCity": selectedCity,
      };
      pBody.query.ExpressionAttributeNames = {
        "#locale": "locale",
        "#mStat": "status",
        "#mName": "name",
        "#address": "address",
        "#townCity": "townCity",
      };
      pBody.limit = defaultPageSize;
      pBody.query.FilterExpression = "contains (#address.#townCity, :townCity)";
      pBody.query.ScanIndexForward = vSearcCriteria.scanIndexForward;
    } else {
      if (vSearcCriteria.useIndex === localStatusIndexName) {
        pBody.query.KeyConditionExpression =
          "#locale = :vLocale and #stat = :vStatus";
        pBody.query.ExpressionAttributeValues = {
          ":vLocale": locale,
          ":vStatus": filterStatus,
          ":townCity": selectedCity,
        };
        pBody.query.ExpressionAttributeNames = {
          "#locale": "locale",
          "#stat": "status",
          "#mStat": "status",
          "#mName": "name",
          "#address": "address",
          "#townCity": "townCity",
        };
        pBody.limit = defaultPageSize;
        pBody.query.FilterExpression =
          "contains (#address.#townCity, :townCity)";
        pBody.query.ScanIndexForward = true;
      } else {
        pBody.query.KeyConditionExpression = "#locale = :vLocale";
        pBody.query.ExpressionAttributeValues = {
          ":vLocale": locale,
          ":townCity": selectedCity,
        };
        pBody.query.ExpressionAttributeNames = {
          "#locale": "locale",
          "#mStat": "status",
          "#mName": "name",
          "#address": "address",
          "#townCity": "townCity",
        };
        pBody.limit = defaultPageSize;
        pBody.query.FilterExpression =
          "contains (#address.#townCity, :townCity)";
        pBody.query.ScanIndexForward = vSearcCriteria.scanIndexForward;
      }
    }

    if (paging.next) {
      pBody.query.ExclusiveStartKey = paging.next;
    }
    if (searchText) {
      pBody.query.FilterExpression =
        "contains (email, :vEmail) OR contains (fullName, :vFullName) OR contains (companyNameLower, :vCompanyName) OR contains (phone, :phone) AND contains (#address.#townCity, :townCity)";
      pBody.query.ExpressionAttributeValues[
        ":vEmail"
      ] = searchText.toLowerCase();
      pBody.query.ExpressionAttributeValues[
        ":vFullName"
      ] = searchText.toLowerCase();
      pBody.query.ExpressionAttributeValues[
        ":phone"
      ] = searchText.toLowerCase();
      pBody.query.ExpressionAttributeValues[
        ":vCompanyName"
      ] = searchText.toLowerCase();
    }
    getAppData(JSON.stringify(pBody))
      .then((resData) => {
        if (resData && resData.items && resData.items.length > 0) {
          setCntLoading(false);
          setPageData({
            items: resData.items,
            nextToken: resData.nextToken,
          });
        } else {
          setCntLoading(false);
          setPageData({
            items: [],
          });
        }
      })
      .catch((error) => {
        console.log(
          "Error occured while calling in AppData search function: ",
          error
        );
        setCntLoading(false);
      });
  }

  useEffect(() => {
    if (cntLoading === true) {
      searchMaids(paging.next);
    }
  });

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group>
        {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                tokens,
                prev: null,
                next: null,
              });
              setCntLoading(true);
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )}
        {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
              setCntLoading(true);
            }}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )}
        {nextToken && (
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              tokens.push(nextToken);
              setPaging({
                tokens,
                prev: paging.next,
                next: nextToken,
              });
              setCntLoading(true);

              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
            }}
          >
            Next
            <Icon name="right arrow" />
          </Button>
        )}
      </Button.Group>
    );
  };

  const Sorting = (col) => {
    if (col === "noresponses") {
      setStatus("ALL");
      if (sorting === "noresponses-desc") {
        setSorting("noresponses-asc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeUnresponsesIndexName,
          scanIndexForward: true,
        });
        setCntLoading(true);
      } else if (sorting === "noresponses-asc") {
        setSorting("dateregistered-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeDateRegisteredIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      } else {
        setSorting("noresponses-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeUnresponsesIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      }
    } else if (col === "dateregistered") {
      setStatus("ALL");
      if (sorting === "dateregistered-asc") {
        setSorting("dateregistered-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeDateRegisteredIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      } else {
        setSorting("dateregistered-asc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeDateRegisteredIndexName,
          scanIndexForward: true,
        });
        setCntLoading(true);
      }
    } else if (col === "enquiries") {
      setStatus("ALL");
      if (sorting === "enquiries-desc") {
        setSorting("enquiries-asc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeEnquiriesIndexName,
          scanIndexForward: true,
        });
        setCntLoading(true);
      } else if (sorting === "enquiries-asc") {
        setSorting("dateregistered-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeDateRegisteredIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      } else {
        setSorting("enquiries-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeEnquiriesIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      }
    } else if (col === "reviews") {
      setStatus("ALL");
      if (sorting === "reviews-desc") {
        setSorting("reviews-asc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeUserReviewsIndexName,
          scanIndexForward: true,
        });
        setCntLoading(true);
      } else if (sorting === "reviews-asc") {
        setSorting("dateregistered-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeDateRegisteredIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      } else {
        setSorting("reviews-desc");
        setSearchCriteria({
          ...searchCriteria,
          useIndex: localeUserReviewsIndexName,
          scanIndexForward: false,
        });
        setCntLoading(true);
      }
    }
  };

  const TableView = ({ result }) => {
    const { items: maids = [], nextToken } = result;

    const { tokens } = paging;

    if (maids.length === 0) {
      return <h2>No results found</h2>;
    }

    return (
      <>
        <br />
        <Paging nextToken={nextToken} tokens={tokens} />
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Company Name/Name</Table.HeaderCell>
              <Table.HeaderCell>Postcode</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => {
                  Sorting("dateregistered");
                }}
                className="sortableHeaderCell"
              >
                <div>
                  <div>Date Registered</div>
                  <div className="aligner padLeft ">
                    {sorting === "dateregistered-desc" ? (
                      <Icon
                        name="angle down"
                        color="black"
                        className="aligned"
                      />
                    ) : null}
                    {sorting === "dateregistered-asc" ? (
                      <Icon name="angle up" color="black" className="aligned" />
                    ) : null}
                  </div>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Maid Updated</Table.HeaderCell>
              <Table.HeaderCell>Offering trial</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Boosted</Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => {
                  Sorting("reviews");
                }}
                className="sortableHeaderCell"
              >
                <div>
                  <div>Reviews</div>
                  <div className="aligner padLeft ">
                    {sorting === "reviews-desc" ? (
                      <Icon
                        name="angle down"
                        color="black"
                        className="aligned"
                      />
                    ) : null}
                    {sorting === "reviews-asc" ? (
                      <Icon name="angle up" color="black" className="aligned" />
                    ) : null}
                  </div>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => {
                  Sorting("enquiries");
                }}
                className="sortableHeaderCell"
              >
                <div>
                  <div>Enquiries</div>
                  <div className="aligner padLeft ">
                    {sorting === "enquiries-desc" ? (
                      <Icon
                        name="angle down"
                        color="black"
                        className="aligned"
                      />
                    ) : null}
                    {sorting === "enquiries-asc" ? (
                      <Icon name="angle up" color="black" className="aligned" />
                    ) : null}
                  </div>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => {
                  Sorting("noresponses");
                }}
                className="sortableHeaderCell"
              >
                <div>
                  <div>Awaiting responses</div>
                  <div className="aligner padLeft ">
                    {sorting === "noresponses-desc" ? (
                      <Icon
                        name="angle down"
                        color="black"
                        className="aligned"
                      />
                    ) : null}
                    {sorting === "noresponses-asc" ? (
                      <Icon name="angle up" color="black" className="aligned" />
                    ) : null}
                  </div>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <RowContent maids={maids} />
        </Table>
        <Paging nextToken={nextToken} tokens={tokens} />
      </>
    );
  };

  const RowContent = ({ maids }) => (
    <Table.Body>
      {maids.map((maid) => {
        const {
          conversations,
          unresponses = "0",
          boost = false,
          userId,
          companyName,
          rate,
          dateRegistered,
          lastUpdatedDate,
          offerTrial,
          image,
          status,
          enquiries,
          userReviews = "0",
        } = maid;

        const userConversations = conversations.items.filter(
          (x) => !x.isSupport
        );
        const name = maid.name;
        const address = maid.address;
        const { postcode, townCity } = address;
        const geoJson = JSON.parse(maid.geoJson);
        const coordinates = geoJson.coordinates.reverse().join("%2C");
        const dateCreated = moment(dateRegistered, "x").format(
          "DD/MM/YYYY HH:mm"
        );
        const dateUpdated = lastUpdatedDate
          ? moment(lastUpdatedDate, "x").format("DD/MM/YYYY HH:mm")
          : "";

        const mapLink = `https://maps.google.com/?q=${coordinates}&z=16`;

        return (
          <Table.Row key={userId}>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>
                  {companyName && (
                    <Header as="h3" block>
                      {companyName}
                    </Header>
                  )}
                  <Header as="h3">
                    {image && <Image src={image} circular />}
                    {name.firstName} {name.lastName || ""}
                  </Header>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>
                  {postcode}
                  <Header.Subheader>
                    <Label
                      as="a"
                      href={mapLink}
                      target="_blank"
                      content="Map"
                      icon="map"
                      className="mapLink"
                    />
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{townCity || ""}</Table.Cell>
            <Table.Cell>{rate || ""}</Table.Cell>
            <Table.Cell>{dateCreated}</Table.Cell>
            <Table.Cell>{dateUpdated}</Table.Cell>
            <Table.Cell>
              {offerTrial ? (
                <Icon name="check" color="green" size="large" />
              ) : (
                <Icon name="close" color="red" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>{status}</Table.Cell>
            <Table.Cell>
              {boost ? (
                <Icon name="check" color="green" size="large" />
              ) : (
                <Icon name="close" color="red" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>{userReviews || "0"}</Table.Cell>
            <Table.Cell>{enquiries || "0"}</Table.Cell>
            <Table.Cell>{unresponses || "0"}</Table.Cell>
            <Table.Cell>
              <Button.Group>
                <Button
                  primary
                  as={Link}
                  to={`/maid/${userId}?url=/maids&name=Maids`}
                  target="_blank"
                >
                  View
                </Button>
                  <Button
                    secondary
                    as={Link}
                    to={`/conversation/${userId}?s=1&url=/maids&name=Maids`}
                  >
                    Message
                  </Button>
              </Button.Group>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  const MaidList = () => {
    if (cntLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Processing your request.
          </Message.Content>
        </Message>
      );
    }
    if (pageData.items && pageData.items.length > 0) {
      return (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" color="grey">
                  Displaying {pageData.items.length} maids
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <TableView result={pageData} />
        </>
      );
    }
    return <h3 className="text-danger">No matching records found</h3>;
  };

  const handleSendMailFunc = async () => {
    setopenMailConfirm(null);
    const url = `${API_GATEWAY}prod/oneTimeMailToMaids`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: TIMEOUT,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
    })
      .then((res) => {
        if (res.data?.statusCode === 200) {
          return setopenMailConfirm(
            <SweetAlert
              success
              title="Good job!"
              onConfirm={() => setopenMailConfirm(null)}
              customButtons={
                <React.Fragment>
                  <Button primary onClick={() => setopenMailConfirm(null)}>
                    Ok
                  </Button>
                </React.Fragment>
              }
            >
              Mail sent successfully!
            </SweetAlert>
          );
        }
        return res.data;
      })
      .catch((error) => {
        console.log("Error retrieving report data (handleSendMailFunc)", error);
        if (error) {
          return setopenMailConfirm(
            <SweetAlert
              error
              title="Something went wrong!"
              onConfirm={() => setopenMailConfirm(null)}
              customButtons={
                <React.Fragment>
                  <Button primary onClick={() => setopenMailConfirm(null)}>
                    Ok
                  </Button>
                </React.Fragment>
              }
            >
              Mail sent faild, Please try again
            </SweetAlert>
          );
        }
        return error;
      });
  };

  const handleConfirmSendMail = () => {
    setopenMailConfirm(
      <SweetAlert
        warning
        showCancel
        title="Are you sure to send mail?"
        onConfirm={() => handleSendMailFunc()}
        onCancel={() => setopenMailConfirm(null)}
        customButtons={
          <React.Fragment>
            <Button secondary onClick={() => setopenMailConfirm(null)}>
              Cancel
            </Button>
            <Button primary onClick={() => handleSendMailFunc()}>
              Send
            </Button>
          </React.Fragment>
        }
      >
        Mail will be sent only to the active maids
      </SweetAlert>
    );
  };

  // Function to remove duplicate cities based on the city name
  const removeDuplicates = (cityArray) => {
    const uniqueCities = cityArray.filter(
      (city, index, self) =>
        index === self.findIndex((c) => c.name === city.name)
    );
    return uniqueCities;
  };

  useEffect(() => {
    let countryCode = locale.replace("en-", "");
    if (countryCode) {
      const countryStates = State.getStatesOfCountry(countryCode);
      let allCities = [];

      countryStates.forEach((state) => {
        const stateCities = City.getCitiesOfState(countryCode, state.isoCode);
        // If the state has no cities, use the state name as a fallback.
        if (stateCities.length === 0) {
          allCities.push({
            name: state.name,
          });
        } else {
          allCities = [...allCities, ...stateCities];
        }
      });

      // Remove duplicate cities
      const uniqueCities = removeDuplicates(allCities);

      const allCityOptions = uniqueCities.map((city) => ({
        key: city.name,
        text: city.name,
        value: city.name,
      }));
      setCities(allCityOptions);
      // Initially set the first batch of cities
      setDisplayedCities(allCityOptions.slice(0, batchSize));
    }
  }, [locale]);

  // Function to filter and batch cities based on search input
  const handleSearchChange = (e, { searchQuery }) => {
    // Filter cities based on the search query
    const filteredCities = cities.filter((city) =>
      city.text.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Display the first batch of the filtered cities
    setDisplayedCities(filteredCities.slice(0, batchSize));
  };

  useEffect(() => {
    if (location?.state?.filterby) {
      setSearchCriteria((prevCriteria) => ({
        ...prevCriteria,
        useIndex: localStatusIndexName,
        scanIndexForward: true,
      }));
    }
  }, [location?.state?.filterby]);

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    { key: "maids", content: "Maids", active: true },
  ];

  return (
    <BaseLayout>
      <Grid divided>
        <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">Maids</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div className="justify-content-between card-item search-info">
            <Form className="w-100">
              <Form.Input
                label="Search for"
                placeholder="Name, Company, Email..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input"
              >
                <img src="/images/svg/search.svg" alt="Search" className="form-seach-icon" />
                <input />
              </Form.Input>
              <Form.Select
                className="status-filter"
                label="Status"
                placeholder="Select status"
                options={statuses}
                value={filterStatus}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  if (value === "ALL") {
                    setSorting("dateregistered-desc");
                    setSearchCriteria({
                      ...searchCriteria,
                      useIndex: localeDateRegisteredIndexName,
                      scanIndexForward: false,
                    });
                  } else {
                    setSorting("dateregistered-desc");
                    setSearchCriteria({
                      ...searchCriteria,
                      useIndex: localStatusIndexName,
                      scanIndexForward: true,
                    });
                  }
                  setStatus(value);
                  // setCntLoading(true);
                }}
              />
              <Form.Select
                className="country-filter"
                label="Country"
                placeholder="Select country"
                options={countries}
                value={locale}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  setLocale(value);
                  setSelectedCity(() => (value !== locale ? "" : selectedCity));
                  // setCntLoading(true);
                }}
              />
              <Form.Select
                className="city-filter"
                label="City"
                placeholder={"Select city"}
                options={displayedCities} // Use the batched and filtered cities
                value={selectedCity}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  setSelectedCity(value);
                }}
                onSearchChange={handleSearchChange} // Handle search input
                search
              />
              <Button color="green" onClick={searchMaids}>
                Search
              </Button>
              <Button
                color="red"
                onClick={() => {
                  setStatus("ALL");
                  setLocale("en-GB");
                  setSelectedCity("");
                  setSearchCriteria({
                    limit: 50,
                  });
                  setSearchText("");
                  setPaging(getPagingData);
                  searchMaids();
                }}
              >
                Reset
              </Button>
            </Form>
          </div>
        </Grid.Row>
      </Grid>
      <MaidList />
    </BaseLayout>
  );
};

export default withAuthenticator(Maids);
