import React, { useEffect, useState } from "react";
import "./styles.css";
import BaseLayout from "../../components/templates/BaseLayout";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";
import {
  Form,
  Header,
  HeaderContent,
  HeaderSubheader,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Image,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  ModalActions,
  Icon,
  Message,
} from "semantic-ui-react";
import { API_GATEWAY } from "../../constants/Urls";
import { Auth, graphqlOperation } from "aws-amplify";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { Connect } from "aws-amplify-react";
import { allMessageConnection } from "../../graphql/queries";
import SweetAlert from "react-bootstrap-sweetalert";

const AiBlockedUsers = ({ history }) => {
  const todayDate = new Date().toLocaleDateString("en-US");
  const defaultUserType = "all-user-types";
  const defaultStatus = "all-status";
  const defaultProfilePic = "/images/logo.jpg";
  const getPagingData = {
    tokens: [],
    prev: null,
    next: null,
  };

  const [selectedUserType, setSelectedUserType] = useState(defaultUserType);
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
  const [isShowReviewModal, setIsShowReviewModal] = useState(false);
  const [cardsCountData, setCardsCountData] = useState();
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isTableCntLoading, setIsTableCntLoading] = useState(false);
  const [isBlockUnblockUser, setIsBlockUnblockUser] = useState(false);
  const [usersTableListData, setUsersTableListData] = useState([]);
  const [reviewModalData, setReviewModalData] = useState({});
  const [paging, setPaging] = useState(getPagingData);
  const [isConfirmPopup, setIsConfirmPopup] = useState(null);

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    { key: "ai-blocked-users", content: "Users Blocked by AI", active: true },
  ];

  const userTypeOptions = [
    { key: "all-user-types", value: "all-user-types", text: "All User Types" },
    { key: "client", value: "customer", text: "Client" },
    { key: "maid", value: "maid", text: "Maid" },
  ];

  const statusOptions = [
    { key: "all-status", value: "all-status", text: "All Statuses" },
    { key: "pending", value: "Pending", text: "Pending" },
    { key: "blocked", value: "Blocked", text: "Blocked" },
    { key: "unBlocked", value: "Unblocked", text: "UnBlocked" },
  ];

  const handlerGetCardsCountData = async () => {
    setIsCardLoading(true);
    const url = `${API_GATEWAY}prod/admin-dashboard-stats`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
      data: { startDate: todayDate, endDate: todayDate },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsCardLoading(false);
          setCardsCountData(res?.data);
        } else {
          setIsCardLoading(false);
        }
      })
      .catch((error) => {
        console.log("handlerGetCardsCountData: ", error);
        setIsCardLoading(false);
      });
  };

  const handlerGetAIBlockedUsresData = async (nextToken) => {
    setIsTableCntLoading(true);
    const nextTokenQuery = nextToken ? `nextToken=${nextToken}` : "";
    const userTypeQuery =
      selectedUserType !== defaultUserType
        ? `userType=${selectedUserType}`
        : "";
    const userStatusQuery =
      selectedStatus !== defaultStatus ? `reviewStatus=${selectedStatus}` : "";

    // Filter out empty query parts and join them with '&'
    const queryParams = [nextTokenQuery, userTypeQuery, userStatusQuery]
      .filter((query) => query) // Remove empty strings
      .join("&");

    // Construct the full URL
    const apiURL = `${API_GATEWAY}prod/v2/ai-banned-users?limit=5${
      queryParams ? `&${queryParams}` : ""
    }`;

    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "GET",
      url: apiURL,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
    })
      .then((res) => {
        if (res?.status === 200 || res?.data?.success) {
          setUsersTableListData(res?.data);
          setIsTableCntLoading(false);
        } else {
          setIsTableCntLoading(false);
        }
      })
      .catch((error) => {
        console.log("handlerGetAIBlockedUsresData: ", error);
        setIsTableCntLoading(false);
      });
  };

  const handlerBlockUnblockUser = async (userID, blockedOn, actionParams) => {
    setIsBlockUnblockUser(true);
    const url = `${API_GATEWAY}prod/v2/ai-banned-users/update-review-status`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
      data: {
        userId: userID ?? "",
        blockedOn: blockedOn,
        reviewStatus: actionParams ?? "",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsBlockUnblockUser(false);
          setIsShowReviewModal(false);
          handlerGetCardsCountData();
          setIsConfirmPopup(null);
          handlerGetAIBlockedUsresData();
        } else {
          setIsBlockUnblockUser(false);
        }
      })
      .catch((error) => {
        console.log("handlerBlockUnblockUser: ", error);
        setIsBlockUnblockUser(false);
      });
  };

  const Paging = ({ nextToken, tokens }) => {
    return (
      <div className="paging-btn-style">
        <Button.Group>
          {tokens.length > 1 && (
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                tokens.splice(0, tokens.length);
                setPaging({
                  tokens,
                  prev: null,
                  next: null,
                });
                handlerGetAIBlockedUsresData();
              }}
            >
              First
              <Icon name="fast backward" />
            </Button>
          )}
          {paging.next && (
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                tokens.pop();
                setPaging({
                  tokens,
                  prev: tokens[tokens.length - 1],
                  next: paging.prev,
                });
                handlerGetAIBlockedUsresData(paging.prev);
              }}
            >
              Prev
              <Icon name="left arrow" />
            </Button>
          )}
          {nextToken && (
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                tokens.push(nextToken);
                setPaging({
                  tokens,
                  prev: paging.next,
                  next: nextToken,
                });
                handlerGetAIBlockedUsresData(nextToken);
              }}
            >
              Next
              <Icon name="right arrow" />
            </Button>
          )}
        </Button.Group>
      </div>
    );
  };

  const handlerFetchConversations = (
    conversationId,
    messageId,
    flggedReason
  ) => {
    return (
      <Connect
        query={graphqlOperation(allMessageConnection, { conversationId })}
      >
        {({ data: { allMessageConnection }, loading }) => {
          if (loading || !allMessageConnection)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Processing your request.
                </Message.Content>
              </Message>
            );

          const sortedMessages = Array.isArray(allMessageConnection?.messages)
            ? allMessageConnection.messages.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              )
            : [];
          return (
            <div className="review-history-table-wrapper">
              <Table basic="very" className="review-history-table" collapsing>
                <TableBody>
                  {sortedMessages.length > 0 ? (
                    sortedMessages.map((conversation, index) => {
                      const convUserType =
                        conversation?.author?.userType === "Maids"
                          ? "Maid"
                          : conversation?.author?.userType === "Customers"
                          ? "Client"
                          : conversation?.author?.userType || "";
                      const convUserTypeBageClass =
                        convUserType === "Maid"
                          ? "ai-maid-badge mt-1"
                          : convUserType === "Client"
                          ? "ai-client-badge mt-1"
                          : convUserType === "Admin"
                          ? "ai-admin-badge mt-1"
                          : "mt-1";
                      const convDateTime = conversation?.createdAt
                        ? moment(conversation?.createdAt, "x")?.format(
                            "DD/MM/YYYY, HH:mm"
                          )
                        : "-";
                      const isFalggedMessage = messageId === conversation?.id;
                      return (
                        <TableRow
                          key={index}
                          className={
                            convUserType === "Maid" && !isFalggedMessage
                              ? "review-maid-row"
                              : isFalggedMessage
                              ? "review-flagged-row"
                              : ""
                          }
                        >
                          <TableCell>
                            <Header as="h4">
                              <HeaderContent>
                                <div>
                                  {`${conversation?.author?.firstName ||
                                    ""} ${conversation?.author?.lastName ||
                                    ""}`}
                                </div>
                                <HeaderSubheader
                                  className={convUserTypeBageClass}
                                >
                                  {convUserType}
                                </HeaderSubheader>
                              </HeaderContent>
                            </Header>
                          </TableCell>
                          <TableCell className="review-date">
                            {convDateTime?.split(",")[0] || "-"}
                            <br />
                            {convDateTime?.split(",")[1] || ""}
                          </TableCell>
                          <TableCell>
                            <div>{conversation?.content}</div>
                            {isFalggedMessage && flggedReason && (
                              <ul className="flagged-list">
                                <li className="flagged-item">
                                  <strong>Flagged: </strong>
                                  {flggedReason}
                                </li>
                              </ul>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <h4 className="text-danger mt-20 mb-20">
                      No records found.
                    </h4>
                  )}
                </TableBody>
              </Table>
            </div>
          );
        }}
      </Connect>
    );
  };
  const handlerBlockUnblockConfirmPoup = (userID, blockedOn, actionParams) => {
    setIsConfirmPopup(
      <SweetAlert
        warning
        showCancel
        title={`Are you sure to ${actionParams} the user.`}
        onConfirm={() =>
          handlerBlockUnblockUser(userID, blockedOn, actionParams)
        }
        onCancel={() => setIsConfirmPopup(null)}
        customButtons={
          <React.Fragment>
            <Button secondary onClick={() => setIsConfirmPopup(null)}>
              No
            </Button>
            <Button
              primary
              onClick={() =>
                handlerBlockUnblockUser(userID, blockedOn, actionParams)
              }
            >
              Yes
            </Button>
          </React.Fragment>
        }
      ></SweetAlert>
    );
  };

  useEffect(() => {
    handlerGetCardsCountData();
  }, []);
  useEffect(() => {
    handlerGetAIBlockedUsresData();
  }, [selectedUserType, selectedStatus]);

  return (
    <div className="ai-blocked-users-layout">
      <BaseLayout>
        <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <div className="overview-block">
          <Header as="h2" className="mb-0">
            Overview of Users Blocked by AI
          </Header>
        </div>
        <div className="ai-blocked-card-outer">
          <div className="ai-blocked-card">
            <span className="card-subtitle">Pending Reviews</span>
            {isCardLoading ? (
              <Skeleton width={60} className="mt-15" />
            ) : (
              <h2 className="pending-review">
                {cardsCountData?.TotalAIBannedPending}
              </h2>
            )}
          </div>

          <div className="ai-blocked-card">
            <span className="card-subtitle">Blocks Upheld</span>
            {isCardLoading ? (
              <Skeleton width={60} className="mt-15" />
            ) : (
              <h2 className="block-review">
                {cardsCountData?.TotalAIBannedBlocked}
              </h2>
            )}
          </div>

          <div className="ai-blocked-card">
            <span className="card-subtitle">Blocks Undone</span>
            {isCardLoading ? (
              <Skeleton width={60} className="mt-15" />
            ) : (
              <h2 className="block-review">
                {cardsCountData?.TotalAIBannedUnblocked}
              </h2>
            )}
          </div>
        </div>
        <div className="ai-block-table-wrapper">
          <div className="ai-block-input-wrapper">
            {/* <Form.Input
              placeholder="Search by name, user name, or email address..."
              className="search-input"
            >
              <img src="/images/svg/search.svg" alt="Search" className="form-seach-icon" />
              <input />
            </Form.Input> */}
            <Form.Select
              className="d-flex"
              placeholder="Select state"
              options={userTypeOptions}
              value={selectedUserType}
              onChange={(event, { value }) => {
                setSelectedUserType(value);
                setSelectedStatus(defaultStatus);
              }}
            />

            <Form.Select
              className="d-flex"
              placeholder="Select city"
              options={statusOptions}
              value={selectedStatus}
              onChange={(event, { value }) => {
                setSelectedStatus(value);
                setSelectedUserType(defaultUserType);
              }}
            />
          </div>

          <Table basic="very" className="ai-block-table" collapsing>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>User</TableHeaderCell>
                <TableHeaderCell>Blocked on</TableHeaderCell>
                <TableHeaderCell>Blocked due to</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Actions</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {!isTableCntLoading && usersTableListData?.items?.length > 0
                ? usersTableListData?.items?.map((userData, index) => {
                    const userType =
                      userData?.userType === "maid"
                        ? "Maid"
                        : userData?.userType === "customer"
                        ? "Client"
                        : "";
                    const userTypeBageClass =
                      userType === "Maid"
                        ? "ai-maid-badge mt-2"
                        : userType === "Client"
                        ? "ai-client-badge mt-2"
                        : "mt-2";
                    const maidUSerName =
                      userData?.userDetails?.name?.firstName +
                      " " +
                      userData?.userDetails?.name?.lastName;
                    const clientUserName =
                      userData?.userDetails?.firstName +
                      " " +
                      userData?.userDetails?.lastName;
                    const finalUserName =
                      userType === "Client"
                        ? clientUserName
                        : userType === "Maid"
                        ? maidUSerName
                        : "";
                    const blockedOnDateTime = userData?.blockedOn
                      ? moment(userData?.blockedOn, "x")?.format(
                          "DD/MM/YYYY, HH:mm"
                        )
                      : "-";
                    const userStausBageClass =
                      userData?.reviewStatus === "Pending"
                        ? "pending-status"
                        : userData?.reviewStatus === "Blocked"
                        ? "block-status"
                        : userData?.reviewStatus === "Unblocked"
                        ? "unblock-status"
                        : "";
                    const userProfileUrl =
                      userType === "Maid"
                        ? `/maid/${userData?.userId}?url=/aiblockedusers&name=Users Blocked by AI`
                        : userType === "Client"
                        ? `/customer/${userData?.userId}?url=/aiblockedusers&name=Users Blocked by AI`
                        : "";
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Header as="h4" image>
                            <Image
                              src={
                                userData?.userDetails?.image ||
                                defaultProfilePic
                              }
                              circular
                              size="mini"
                            />

                            <HeaderContent>
                              <div>{finalUserName}</div>
                              <HeaderSubheader className={userTypeBageClass}>
                                {userType}
                              </HeaderSubheader>
                            </HeaderContent>
                          </Header>
                        </TableCell>
                        <TableCell>
                          {blockedOnDateTime?.split(",")[0] || "-"}
                          <br />
                          {blockedOnDateTime?.split(",")[1] || ""}
                        </TableCell>
                        <TableCell>
                          <Header as="h4" image>
                            <HeaderContent>
                              {userData?.category || ""}
                              <HeaderSubheader className="mt-2">
                                {userData?.explicitReason || ""}
                              </HeaderSubheader>
                            </HeaderContent>
                          </Header>
                        </TableCell>
                        <TableCell>
                          <span className={userStausBageClass}>
                            {userData?.reviewStatus}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div className="btn-group">
                            <Button
                              primary
                              onClick={() => {
                                setIsShowReviewModal(true);
                                setReviewModalData({
                                  userData,
                                  userType,
                                  userTypeBageClass,
                                  finalUserName,
                                  blockedOnDateTime,
                                  userProfileUrl,
                                });
                              }}
                            >
                              Review
                            </Button>
                            {userData?.reviewStatus === "Pending" && (
                              <>
                                <Button
                                  onClick={() => {
                                    handlerBlockUnblockConfirmPoup(
                                      userData?.userId,
                                      userData?.blockedOn,
                                      "Unblocked"
                                    );
                                  }}
                                  disabled={isBlockUnblockUser}
                                >
                                  Unblock
                                </Button>

                                <Button
                                  color="red"
                                  onClick={() => {
                                    handlerBlockUnblockConfirmPoup(
                                      userData?.userId,
                                      userData?.blockedOn,
                                      "Blocked"
                                    );
                                  }}
                                  disabled={isBlockUnblockUser}
                                >
                                  Uphold block
                                </Button>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : !isTableCntLoading && (
                    <h4 className="text-danger mt-20 mb-20">
                      No records found.
                    </h4>
                  )}
            </TableBody>
          </Table>
          {isTableCntLoading && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                Processing your request.
              </Message.Content>
            </Message>
          )}
        </div>
        <Paging
          nextToken={usersTableListData?.nextToken}
          tokens={paging?.tokens}
        />
      </BaseLayout>
      <Modal
        closeIcon
        className="review-ai-modal"
        onClose={() => setIsShowReviewModal(false)}
        open={isShowReviewModal}
      >
        <ModalHeader className="review-block-heading">Review Block</ModalHeader>
        <ModalContent scrolling>
          <div className="info-wrapper">
            <div className="info-inner">
              <Header as="h3">User Information</Header>
              <div className="info-box profile-box">
                <div className="profile-block">
                  <Image
                    src={
                      reviewModalData?.userData?.userDetails?.image ||
                      defaultProfilePic
                    }
                    circular
                    size="mini"
                  />
                  <HeaderContent>
                    <h4 className="profile-name">
                      {reviewModalData?.finalUserName}
                    </h4>
                    <HeaderSubheader
                      className={reviewModalData?.userTypeBageClass}
                    >
                      {reviewModalData?.userType}
                    </HeaderSubheader>
                  </HeaderContent>
                </div>
                <Button
                  primary
                  as={Link}
                  to={() => reviewModalData?.userProfileUrl}
                >
                  View Profile
                </Button>
              </div>
              <ul className="user-info-list">
                <li className="user-info-item">
                  <span className="review-block-title">Member since</span>
                  <span className="user-value">
                    {reviewModalData?.userData?.userDetails?.dateRegistered ||
                    reviewModalData?.userData?.userDetails?.dateCreated
                      ? moment(
                          reviewModalData?.userType === "Maid"
                            ? reviewModalData?.userData?.userDetails
                                ?.dateRegistered
                            : reviewModalData?.userType === "Client"
                            ? reviewModalData?.userData?.userDetails
                                ?.dateCreated
                            : null,
                          "x"
                        )?.format("DD/MM/YYYY")
                      : "-"}
                  </span>
                </li>
                <li className="user-info-item">
                  <span className="review-block-title">Email</span>
                  <span className="user-value">
                    {reviewModalData?.userData?.userDetails?.email || "-"}
                  </span>
                </li>
                <li className="user-info-item">
                  <span className="review-block-title">Last active</span>
                  <span className="user-value">
                    {reviewModalData?.userData?.userDetails?.lastActive ? (
                      <ReactTimeAgo
                        date={
                          new Date(
                            Number(
                              reviewModalData?.userData?.userDetails?.lastActive
                            )
                          )
                        }
                        locale="en-US"
                      />
                    ) : (
                      "Ancient history"
                    )}
                  </span>
                </li>
                <li className="user-info-item">
                  <span className="review-block-title">Notifications</span>
                  <span
                    className={`user-value ${reviewModalData?.userData
                      ?.pushNotification && "enable-value"}`}
                  >
                    {reviewModalData?.userData?.pushNotification === true
                      ? "Enabled"
                      : !reviewModalData?.userData?.pushNotification === false
                      ? "Disabled"
                      : "-"}
                  </span>
                </li>
                <li className="user-info-item">
                  <span className="review-block-title">Maids Contacted</span>
                  <span className="user-value">
                    {reviewModalData?.userData?.enquires || "-"}
                  </span>
                </li>
                <li className="user-info-item">
                  <span className="review-block-title">Someone's client</span>
                  <span className="user-value">
                    {reviewModalData?.userData?.userDetails?.clients > 0
                      ? "Yes"
                      : "No"}
                  </span>
                </li>
              </ul>
            </div>
            <div className="info-inner">
              <Header as="h3">Block Information</Header>

              <div className="block-section">
                <div className="review-block-title">Reason for block</div>
                <div className="warning-box">
                  <div className="warning-title">
                    {reviewModalData?.userData?.category}
                  </div>
                  <div className="warning-description">
                    {reviewModalData?.userData?.explicitReason}
                  </div>
                </div>
              </div>

              <div className="block-section">
                <div className="review-block-title">Blocked on</div>
                <div className="info-box">
                  <div className="info-primary">
                    {reviewModalData?.blockedOnDateTime}
                  </div>
                  <div className="info-secondary">
                    {reviewModalData?.userData?.blockedOn ? (
                      <ReactTimeAgo
                        date={
                          new Date(Number(reviewModalData?.userData?.blockedOn))
                        }
                        locale="en-US"
                      />
                    ) : (
                      "Ancient history"
                    )}
                  </div>
                </div>
              </div>

              <div className="block-section">
                <div className="review-block-title">Previous violations</div>
                <div className="info-box">
                  <div className="info-text">
                    {reviewModalData?.userData?.previousViolations || "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="review-history-wrapper">
            <Header as="h3">Conversation History</Header>
            {handlerFetchConversations(
              reviewModalData?.userData?.conversationId,
              reviewModalData?.userData?.messageId,
              reviewModalData?.userData?.explicitReason
            )}
          </div>
        </ModalContent>
        {reviewModalData?.userData?.reviewStatus === "Pending" && (
          <ModalActions>
            <Button
              onClick={() => {
                handlerBlockUnblockConfirmPoup(
                  reviewModalData?.userData?.userId,
                  reviewModalData?.userData?.blockedOn,
                  "Unblocked"
                );
              }}
              disabled={isBlockUnblockUser}
            >
              Unblock
            </Button>
            <Button
              color="red"
              onClick={() => {
                handlerBlockUnblockConfirmPoup(
                  reviewModalData?.userData?.userId,
                  reviewModalData?.userData?.blockedOn,
                  "Blocked"
                );
              }}
              disabled={isBlockUnblockUser}
            >
              Uphold block
            </Button>
          </ModalActions>
        )}
      </Modal>
      {isConfirmPopup}
    </div>
  );
};

export default AiBlockedUsers;
