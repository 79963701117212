/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  FormGroup,
  Container,
  GridRow,
  Button,
  Label,
  Grid,
  GridColumn,
  Divider,
  Header,
  Icon,
  Item,
  Image,
  Form,
  Segment,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Modal,
  Message,
  Checkbox,
  Radio,
  TextArea,
} from "semantic-ui-react";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Rating from "react-rating";
import ConversationsList from "../../molecules/ConversationsList";
import Notifications from "../Notifications";
import {
  addReview,
  deleteReview,
  updateMaid,
  updateReview,
} from "../../../graphql/mutations";
import cleaningServices from "../../../constants/cleaningServices";
import ConversationsListV2 from "../../molecules/ConversationsListV2";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_GATEWAY } from "../../../constants/Urls";
import axios from "axios";
import _ from "lodash";
import { Connect, withAuthenticator } from "aws-amplify-react";
import {
  getMaidContactsAndActive,
  getMaidReviews,
} from "../../../graphql/queries";
import {
  BreadcrumbExampleShorthand,
  getLastActive,
} from "../../molecules/functions";
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const moment = require("moment");

const options = [
  { key: "1", text: "ACTIVE", value: "ACTIVE" },
  { key: "0", text: "PENDING", value: "PENDING" },
  { key: "-1", text: "BLOCKED", value: "BLOCKED" },
];
const ratingOptions = [
  { key: "0", text: "1 stars", value: 1 },
  { key: "1", text: "2 stars", value: 2 },
  { key: "2", text: "3 stars", value: 3 },
  { key: "3", text: "4 stars", value: 4 },
  { key: "4", text: "5 stars", value: 5 },
];

const AccountType = [
  { key: "1", text: "BUSINESS", value: "company" },
  { key: "0", text: "INDIVIDUAL", value: "sole" },
];

const availabilityOptions = [
  { key: "0.5", text: "0.5 day", value: 0.5 },
  { key: "1", text: "1 day", value: 1 },
  { key: "1.5", text: "1.5 days", value: 1.5 },
  { key: "2", text: "2 days", value: 2 },
  { key: "2.5", text: "2.5 days", value: 2.5 },
  { key: "3", text: "3 days", value: 3 },
  { key: "3.5", text: "3.5 days", value: 3.5 },
  { key: "4", text: "4 days", value: 4 },
  { key: "4.5", text: "4.5 days", value: 4.5 },
  { key: "5", text: "5 days", value: 5 },
  { key: "5.5", text: "5.5 days", value: 5.5 },
  { key: "6", text: "6 days", value: 6 },
  { key: "6.5", text: "6.5 days", value: 6.5 },
  { key: "7", text: "7 days", value: 7 },
];

const experienceOptions = () => {
  const years = [];
  for (let i = 0; i < 21; i += 1) {
    years.push({ key: i, text: `${i} years(s)`, value: i });
  }

  return years;
};

const distanceOptions = () => {
  const distances = [];
  for (let i = 1; i < 26; i += 1) {
    distances.push({ key: i, text: `${i} mile(s)`, value: i });
  }

  return distances;
};

const LEFT_WIDTH = 10;
const RIGHT_WIDTH = 6;

const EditMaid = ({ id, maid, maidHeaderData, location }) => {
  const [updatedMaid, setUpdatedMaid] = useState(maid);
  const [oneTimeMailSend, setOneTimeMailSend] = useState(
    maid?.oneTimeMailSend ?? false
  );
  const [
    mutateMaid,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(gql(updateMaid));

  const [
    addMaidReviewsbyAdmin,
    { loading: addReviewsMutationLoading, error: addReviewsMutationError },
  ] = useMutation(gql(addReview));
  const [
    updateMaidReviewsbyAdmin,
    {
      loading: updateReviewsMutationLoading,
      error: updateReviewsMutationError,
    },
  ] = useMutation(gql(updateReview));
  const [
    deleteMaidReviewsbyAdmin,
    {
      loading: deleteReviewsMutationLoading,
      error: deleteReviewsMutationError,
    },
  ] = useMutation(gql(deleteReview));

  const [formSuccess, setFormSuccess] = useState(false);

  const [imageGallery, setImageGallery] = useState(
    JSON.parse(maid.gallery) || []
  );
  const [dbsFiles, setDbsFiles] = useState([]);
  const [dbsFileObject, setDbsFileObject] = useState(null);
  const [dbsFileName, setDbsFileName] = useState("");
  const [dbsFilesSignedUrls, setDbsFilesSignedUrls] = useState("");
  const [dbsIsUploading, setDbsIsUploading] = useState(false);
  const [dbsStatusCode, setDbsStatusCode] = useState("");
  const [openMailConfirm, setopenMailConfirm] = useState(null);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [isDeleteReviewModal, setIsDeleteReviewModal] = useState(false);
  const [isProfileEqual, setIsProfileEqual] = useState(true);
  const [maidReviewsData, setMaidReviewsData] = useState([]);
  const [authorName, setAuthorName] = useState("");
  const [rating, setRating] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [reviewId, setReviewId] = useState(null);
  const [errors, setErrors] = useState({
    authorName: false,
    rating: false,
    content: false,
  });
  const [isAuthorNameEdit, setIsAuthorNameEdit] = useState(false);
  const [isCallingAPI, setIsCallingAPI] = useState(false);

  const {
    address,
    address: { townCity, county, line1, line2, line3, postcode },
    activeSince,
    availability,
    bio,
    boost = false,
    businessType,
    companyName,
    distance,
    email,
    extraServices,
    hashKey,
    image,
    imageStatus,
    rangeKey,
    name,
    name: { firstName, lastName },
    ownEquipment,
    hasInsurance,
    rate,
    selectedDeep,
    offerTrial,
    status,
    DBSFiles,
    enquiries,
    // user: { phone},
  } = updatedMaid;

  const [dbsFileNames, setDbsFileNames] = useState(DBSFiles || []);

  const isAdminCardChanges =
    maid.status !== updatedMaid.status ||
    maid.businessType !== updatedMaid.businessType ||
    maid.boost !== updatedMaid.boost;

  const conversations =
    maid && maid.conversations && maid.conversations.userConversations
      ? maid.conversations.userConversations
      : [];

  // Handle DBS file input change
  const fileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const newFileNames = files.map((file) => file.name);
    setDbsFiles([...dbsFiles, ...files]); // Add new files to the existing state
    setDbsFileNames([...dbsFileNames, ...newFileNames]); // Add new file names to the existing state
    setIsProfileEqual(false); // Mark profile as changed
    // setUpdatedMaid({
    //   ...updatedMaid,
    //   DBSFiles: newFileNames.join(", "), // Prepare filenames for API payload
    // });
  };

  // Upload DBS multiple files to S3 bucket
  const uploadDbsFiles = async (cognitoId) => {
    const uploadedFileNames = await Promise.all(
      dbsFiles.map(async (file) => {
        setIsCallingAPI(true);
        // const fileExtension = file.name.split(".").pop();
        const filename = `${cognitoId}-dbs_${file.name}`; // Prefix filenames with user ID
        try {
          await Storage.put(filename, file, {
            bucket: "maid-dbs",
            contentType: file.type,
          });
          setIsCallingAPI(false);
          return filename; // Return the uploaded file name
        } catch (err) {
          console.error("Failed to upload file:", file.name, err);
          setIsCallingAPI(false);
          return null; // Handle failed uploads
        }
      })
    );
    return uploadedFileNames.filter((name) => name); // Remove null values
  };

  const save = async (btnType) => {
    setIsCallingAPI(true);
    if (dbsFiles?.length > 0) {
      const uploadedFiles = await uploadDbsFiles(rangeKey);
      if (uploadedFiles?.length > 0) {
        setDbsFiles([]);
        // setFormSuccess(false);
      } else {
        console.error("File upload failed.");
        return;
      }
    }
    const adminCardBody = {
      hashKey,
      rangeKey,
      isAdmin: true,
      boost: updatedMaid.boost,
      businessType: updatedMaid.businessType,
      status: updatedMaid.status,
      ...(updatedMaid.status === "ACTIVE" && { approvedAt: Date.now().toString() }) // if status approved pass this key
    };
    const profileCardBody = {
      hashKey,
      rangeKey,
      isAdmin: true,
      name: JSON.stringify(updatedMaid.name),
      fullName: `${updatedMaid.name.firstName.toLowerCase()} ${updatedMaid.name.lastName.toLowerCase()}`,
      activeSince: updatedMaid.activeSince,
      bio: updatedMaid.bio,
      companyName: updatedMaid.companyName,
      distance: updatedMaid.distance,
      email: updatedMaid.email,
      extraServices: updatedMaid.extraServices,
      availability: updatedMaid.availability,
      address: JSON.stringify(updatedMaid.address),
      rate: updatedMaid.rate,
      hasInsurance: updatedMaid.hasInsurance,
      ownEquipment: updatedMaid.ownEquipment,
      selectedDeep: updatedMaid.selectedDeep,
      offerTrial: updatedMaid.offerTrial,
      imageStatus: updatedMaid.imageStatus,
      DBSFiles: [...dbsFileNames],
    };
    const galleryCardBody = {
      hashKey,
      rangeKey,
      isAdmin: true,
      gallery: JSON.stringify(imageGallery),
    };

    const response = await mutateMaid({
      variables: {
        input:
          btnType === "adminCard"
            ? adminCardBody
            : btnType === "profileCard"
            ? profileCardBody
            : btnType === "gallery"
            ? galleryCardBody
            : {},
      },
    });
    if (response?.data?.updateMaid) {
      setIsCallingAPI(false);
      window.location.reload();
    }
    setFormSuccess(true);
    setTimeout(() => {
      setFormSuccess(false);
      setIsCallingAPI(false);
    }, 5000);
  };

  // Generate signed URLs for DBS file previews
  const getSignedUrls = async (clickedFileName) => {
    try {
      const url = await Storage.get(`${rangeKey}-dbs_${clickedFileName}`, {
        bucket: "maid-dbs",
      });
      return url;
    } catch (err) {
      console.error("Failed to get signed URL for:", err);
      return null;
    }
  };

  const experience = moment().year() - activeSince;

  const deleteImage = (index) => {
    const updatedImageGallery = [...imageGallery];
    updatedImageGallery.splice(index, 1);
    setImageGallery(updatedImageGallery);
  };

  const updateSelectedServices = (service) => {
    let selectedServices = extraServices ? [...extraServices] : [];
    if (selectedServices.indexOf(service) !== -1) {
      selectedServices = selectedServices.filter((x) => x !== service);
    } else {
      selectedServices.push(service);
    }
    setUpdatedMaid({
      ...updatedMaid,
      extraServices: selectedServices,
    });
  };

  const handleSendMailFunc = async () => {
    setopenMailConfirm(null);
    const url = `${API_GATEWAY}prod/oneTimeMailToMaids/${id}`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
    })
      .then((res) => {
        if (res.data?.statusCode === 200) {
          setOneTimeMailSend(true);
          return setopenMailConfirm(
            <SweetAlert
              success
              title="Good job!"
              onConfirm={() => setopenMailConfirm(null)}
              customButtons={
                <React.Fragment>
                  <Button primary onClick={() => setopenMailConfirm(null)}>
                    Ok
                  </Button>
                </React.Fragment>
              }
            >
              Mail sent successfully!
            </SweetAlert>
          );
        }
        return res.data;
      })
      .catch((error) => {
        console.log("Error retrieving report data (handleSendMailFunc)", error);
        if (error) {
          return setopenMailConfirm(
            <SweetAlert
              error
              title="Something went wrong!"
              onConfirm={() => setopenMailConfirm(null)}
              customButtons={
                <React.Fragment>
                  <Button primary onClick={() => setopenMailConfirm(null)}>
                    Ok
                  </Button>
                </React.Fragment>
              }
            >
              {error?.response?.data?.message ??
                "Mail sent faild, Please try again"}
            </SweetAlert>
          );
        }
        return error;
      });
  };

  const handleConfirmSendMail = () => {
    setopenMailConfirm(
      <SweetAlert
        warning
        showCancel
        title="Are you sure to send mail?"
        onConfirm={() => handleSendMailFunc()}
        onCancel={() => setopenMailConfirm(null)}
        customButtons={
          <React.Fragment>
            <Button secondary onClick={() => setopenMailConfirm(null)}>
              Cancel
            </Button>
            <Button primary onClick={() => handleSendMailFunc()}>
              Send
            </Button>
          </React.Fragment>
        }
      >
        Mail will be sent only one time
      </SweetAlert>
    );
  };

  const handleFetchMaidReviews = async () => {
    const response = await API.graphql(
      graphqlOperation(getMaidReviews, {
        maidId: id,
      })
    );
    if (response?.data?.listUsersReviews?.items) {
      const sortedList = response?.data?.listUsersReviews?.items?.sort(
        (a, b) => {
          if (a?.dateCreated > b?.dateCreated) {
            return -1;
          }
          return 1;
        }
      );
      setMaidReviewsData(sortedList);
    } else {
      setMaidReviewsData([]);
    }
  };

  const handleAddMaidRevies = async () => {
    const addReviewsBody = {
      maidId: id,
      rating: rating,
      content: messageContent,
      userId: Auth?.user?.attributes?.sub,
      dateCreated: Date.now().toString(),
      authorName: authorName,
    };
    try {
      setIsCallingAPI(true);
      if (authorName && rating && messageContent) {
        const response = await addMaidReviewsbyAdmin({
          variables: addReviewsBody,
        });
        if (response?.data?.createUsersReviews) {
          setIsCallingAPI(false);
          handleFetchMaidReviews();
          handleCloseReviewModal();
        }
      } else {
        setIsCallingAPI(false);
      }
    } catch (error) {
      console.log("addMaidReviewsbyAdmin error", error);
    }
  };

  const handleFetchReviewDataByIndex = (reviewDetails) => {
    setIsReviewModal(true);
    setAuthorName(
      reviewDetails?.authorName ||
        reviewDetails?.user?.firstName + " " + reviewDetails?.user?.lastName ||
        "Admin"
    );
    setRating(reviewDetails?.rating ?? null);
    setMessageContent(reviewDetails?.content ?? "");
    setReviewId(reviewDetails?.id);
    setIsAuthorNameEdit(reviewDetails?.user?.userType === "Customers");
  };

  const handleUpdateReview = async () => {
    const updateReviewBody = {
      id: reviewId,
      authorName: authorName,
      rating: rating,
      content: messageContent,
      dateCreated: Date.now().toString(),
    };
    try {
      setIsCallingAPI(true);
      if (authorName && rating && messageContent) {
        const response = await updateMaidReviewsbyAdmin({
          variables: updateReviewBody,
        });
        if (response?.data?.updateUsersReviews) {
          setIsCallingAPI(false);
          handleFetchMaidReviews();
          handleCloseReviewModal();
        } else {
          setIsCallingAPI(false);
        }
      }
    } catch (error) {
      console.log("updateMaidReviewsbyAdmin error", error);
    }
  };

  const handleDeleteReview = async () => {
    try {
      const response = await deleteMaidReviewsbyAdmin({
        variables: { id: reviewId },
      });
      if (response?.data?.deleteUsersReviews) {
        handleFetchMaidReviews();
        setIsDeleteReviewModal(false);
        handleCloseReviewModal();
      }
    } catch (error) {
      console.log("deleteMaidReviewsbyAdmin error", error);
    }
  };

  const handleCloseReviewModal = () => {
    if (!isCallingAPI) {
      setIsReviewModal(false);
      setAuthorName("");
      setRating(null);
      setMessageContent("");
      setReviewId(null);
      setErrors({});
      setIsAuthorNameEdit(false);
    }
  };

  useEffect(() => {
    handleFetchMaidReviews();
  }, []);

  useEffect(() => {
    const prevExtraServices = maid.extraServices;
    const updatedExtraServices = updatedMaid.extraServices;
    const extraServicesEqual = _.isEqual(
      _.sortBy(prevExtraServices),
      _.sortBy(updatedExtraServices)
    );

    const notConsiderKey = [
      "boost",
      "status",
      "businessType",
      "extraServices",
      "DBSFiles",
    ];
    const prevMaidDetails = _.omit(maid, notConsiderKey);
    const updatedMaidDetails = _.omit(updatedMaid, notConsiderKey);
    const isDbsFile = maid.DBSFiles === updatedMaid.DBSFiles;

    const isMatchedProfileSection =
      JSON.stringify(prevMaidDetails) === JSON.stringify(updatedMaidDetails) &&
      extraServicesEqual &&
      isDbsFile;

    setIsProfileEqual(isMatchedProfileSection);
  }, [updatedMaid, maid]);

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: location?.state?.prevPathName ?? "maids",
      content: location?.state?.prevPathName ?? "Maids",
      link: true,
      path: location?.state?.prevPathUrl ?? "/maids",
    },
    {
      key: "maid",
      content: "Maid",
      active: true,
    },
  ];

  return (
    <>
      <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
      <Grid.Row>
        <GridColumn width="16">
          <div className="d-flex-center justify-content-between card-item user-info">
            <div className="d-flex-center user-detail">
              {image && (
                <Image
                  className="profile-img"
                  src={image}
                  width="90"
                  height="90"
                />
              )}
              <div className="user-name">
                <h2 className="user-name-heading">
                  {maid.name.firstName} {maid.name.lastName}
                </h2>
                {businessType === "company" && <span>{maid.companyName}</span>}
              </div>
            </div>

            <ul className="user-detail-list">
              <li>
                <span>Clients:</span>
                <h5>{maidHeaderData?.contacts || 0}</h5>
              </li>
              <li>
                <span>Total enquiries:</span>
                <h5>{enquiries || 0}</h5>
              </li>
              <li>
                <span>Last active:</span>
                {/* <h5>{getLastActive(maidHeaderData?.lastActive)}</h5> */}
                <h5>
                  {maidHeaderData?.lastActive ? (
                    <ReactTimeAgo
                      date={new Date(Number(maidHeaderData?.lastActive))}
                      locale="en-US"
                    />
                  ) : (
                    "Ancient history"
                  )}
                </h5>
              </li>
              <li>
                <span>Notifications:</span>
                <Notifications userId={id} />
              </li>
            </ul>
          </div>
        </GridColumn>

        <GridColumn width={LEFT_WIDTH}>
          <div className="card-item mb-20">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="cog" className="mr-8" />
                Admin
              </h3>

              <Button
                color={isAdminCardChanges && !isCallingAPI ? "green" : ""}
                position="right"
                onClick={() => {
                  !isCallingAPI && save("adminCard");
                }}
                disabled={!isAdminCardChanges || isCallingAPI}
                loading={isCallingAPI}
              >
                Save changes
              </Button>
            </Item>

            <Form>
              <FormGroup widths="equal">
                <Form.Select
                  fluid
                  label="Account Status"
                  options={options}
                  placeholder="Status"
                  defaultValue={status}
                  onChange={(_e, data) => {
                    setUpdatedMaid({
                      ...updatedMaid,
                      status: data.value,
                    });
                  }}
                />
                <Form.Select
                  fluid
                  label="Account Type"
                  options={AccountType}
                  placeholder="Business Type"
                  defaultValue={businessType}
                  onChange={(_e, data) => {
                    setUpdatedMaid({
                      ...updatedMaid,
                      businessType: data.value,
                    });
                  }}
                />
              </FormGroup>
              <Form.Group className="mb-0">
                <Form.Field
                  control={Checkbox}
                  label="Boosted Profile"
                  checked={boost}
                  onChange={() =>
                    setUpdatedMaid({ ...updatedMaid, boost: !boost })
                  }
                />
              </Form.Group>
            </Form>
          </div>

          {/* Profile filed */}
          <div className="card-item">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="user" className="mr-8" />
                Profile
              </h3>

              <Button
                color={!isProfileEqual && !isCallingAPI ? "green" : ""}
                position="right"
                onClick={() => {
                  !isCallingAPI && save("profileCard");
                }}
                disabled={isProfileEqual || isCallingAPI}
                loading={isCallingAPI}
              >
                Save changes
              </Button>
            </Item>

            <Form>
              <Grid>
                <GridRow>
                  <GridColumn width={8}>
                    <Form.Input
                      fluid
                      required
                      label="First name"
                      placeholder="First name"
                      value={firstName}
                      onChange={(_e, data) => {
                        const updatedName = { ...name };
                        updatedName.firstName = data.value;
                        setUpdatedMaid({
                          ...updatedMaid,
                          name: updatedName,
                        });
                      }}
                    />
                    <Form.Input
                      fluid
                      required
                      className="mb-16"
                      label="Last name"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(_e, data) => {
                        const updatedName = { ...name };
                        updatedName.lastName = data.value;
                        setUpdatedMaid({
                          ...updatedMaid,
                          name: updatedName,
                        });
                      }}
                    />
                  </GridColumn>

                  <GridColumn width={8}>
                    {image && (
                      <Form.Group className="profile-image">
                        <Form.Field>
                          <Image src={image} width="115" height="115" />
                        </Form.Field>
                        <Form.Select
                          // fluid
                          label="Image Status"
                          options={options}
                          placeholder="Status"
                          defaultValue={imageStatus}
                          onChange={(_e, data) => {
                            setUpdatedMaid({
                              ...updatedMaid,
                              imageStatus: data.value,
                            });
                          }}
                        />
                      </Form.Group>
                    )}
                  </GridColumn>

                  {businessType === "company" && (
                    <GridColumn width={8}>
                      <Form.Input
                        fluid
                        required
                        className="mb-16"
                        label="Business Name"
                        placeholder="Business Name"
                        value={companyName || ""}
                        onChange={(_e, data) => {
                          setUpdatedMaid({
                            ...updatedMaid,
                            companyName: data.value,
                          });
                        }}
                      />
                    </GridColumn>
                  )}

                  <GridColumn width={16}>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(_e, data) => {
                          setUpdatedMaid({
                            ...updatedMaid,
                            email: data.value,
                          });
                        }}
                      />
                      <Form.Input
                        fluid
                        label="Phone"
                        placeholder="Phone"
                        value={updatedMaid?.user?.phone ?? ""}
                        readOnly
                      />
                    </Form.Group>
                  </GridColumn>

                  <GridColumn width={16}>
                    <Form.Field
                      rows={8}
                      value={bio || ""}
                      onChange={(_e, data) => {
                        setUpdatedMaid({ ...updatedMaid, bio: data.value });
                      }}
                      control={TextArea}
                      label="About"
                      placeholder="Tell us more about you..."
                    />
                  </GridColumn>
                </GridRow>

                <Divider section />

                <GridRow>
                  <GridColumn width={8}>
                    <Form.Input
                      label="Address line 1"
                      // width={LEFT_WIDTH}
                      value={line1}
                      onChange={(_e, data) => {
                        const updatedAddress = { ...address };
                        updatedAddress.line1 = data.value;
                        setUpdatedMaid({
                          ...updatedMaid,
                          address: updatedAddress,
                        });
                      }}
                    />
                    <Form.Input
                      label="Address line 2"
                      className="mb-16"
                      // width={LEFT_WIDTH}
                      value={line2}
                      onChange={(_e, data) => {
                        const updatedAddress = { ...address };
                        updatedAddress.line2 = data.value;
                        setUpdatedMaid({
                          ...updatedMaid,
                          address: updatedAddress,
                        });
                      }}
                    />
                  </GridColumn>

                  <GridColumn width={16}>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Town"
                        // width={RIGHT_WIDTH}
                        value={townCity}
                        onChange={(_e, data) => {
                          const updatedAddress = { ...address };
                          updatedAddress.townCity = data.value;
                          setUpdatedMaid({
                            ...updatedMaid,
                            address: updatedAddress,
                          });
                        }}
                      />
                      <Form.Input
                        label="County"
                        // width={RIGHT_WIDTH}
                        value={county}
                        onChange={(_e, data) => {
                          const updatedAddress = { ...address };
                          updatedAddress.county = data.value;
                          setUpdatedMaid({
                            ...updatedMaid,
                            address: updatedAddress,
                          });
                        }}
                      />
                    </Form.Group>
                  </GridColumn>

                  <GridColumn width={8}>
                    <Form.Input
                      required
                      label="Postcode"
                      // width={RIGHT_WIDTH}
                      value={postcode}
                      onChange={(_e, data) => {
                        const updatedAddress = { ...address };
                        updatedAddress.postcode = data.value;
                        setUpdatedMaid({
                          ...updatedMaid,
                          address: updatedAddress,
                        });
                      }}
                    />
                  </GridColumn>
                </GridRow>

                <Divider section />

                <GridRow>
                  <GridColumn width={16}>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Price"
                        // width={RIGHT_WIDTH}
                        value={rate}
                        onChange={(_e, data) =>
                          setUpdatedMaid({
                            ...updatedMaid,
                            rate: data.value,
                          })
                        }
                      />
                      <Form.Select
                        fluid
                        label="Availability"
                        options={availabilityOptions}
                        defaultValue={availability}
                        onChange={(_e, data) => {
                          setUpdatedMaid({
                            ...updatedMaid,
                            availability: data.value,
                          });
                        }}
                      />
                      <Form.Select
                        fluid
                        label="Distance"
                        options={distanceOptions()}
                        defaultValue={distance}
                        onChange={(_e, data) => {
                          setUpdatedMaid({
                            ...updatedMaid,
                            distance: data.value,
                          });
                        }}
                      />
                      <Form.Select
                        fluid
                        label="Experience"
                        options={experienceOptions()}
                        defaultValue={experience > 20 ? 20 : experience}
                        onChange={(_e, data) => {
                          setUpdatedMaid({
                            ...updatedMaid,
                            activeSince: moment().year() - data.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </GridColumn>

                  <GridColumn width={16} className="mt-15">
                    <Form.Group widths="equal">
                      <Form.Field
                        control={Checkbox}
                        label="Is insured"
                        checked={hasInsurance}
                        onChange={() =>
                          setUpdatedMaid({
                            ...updatedMaid,
                            hasInsurance: !hasInsurance,
                          })
                        }
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Has own equipment"
                        checked={ownEquipment}
                        onChange={() =>
                          setUpdatedMaid({
                            ...updatedMaid,
                            ownEquipment: !ownEquipment,
                          })
                        }
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Will Deep Clean"
                        checked={selectedDeep}
                        onChange={() =>
                          setUpdatedMaid({
                            ...updatedMaid,
                            selectedDeep: !selectedDeep,
                          })
                        }
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Offering free trial"
                        checked={offerTrial}
                        onChange={() =>
                          setUpdatedMaid({
                            ...updatedMaid,
                            offerTrial: !offerTrial,
                          })
                        }
                      />
                    </Form.Group>
                  </GridColumn>

                  <GridColumn width={16} className="mt-15">
                    <Form.Group grouped>
                      <label>Additional Services</label>
                      <div className="additional-services">
                        {cleaningServices.map((service) => (
                          // <Form.Input
                          //   key={service}
                          //   className="checkbox"
                          //   label={service}
                          //   control="input"
                          //   inline
                          //   type="checkbox"
                          //   checked={extraServices && extraServices.indexOf(service) > -1}
                          //   onChange={() => updateSelectedServices(service)}
                          //   widths={1}
                          // />
                          <Form.Field
                            key={service}
                            control={Checkbox}
                            label={service}
                            checked={
                              extraServices &&
                              extraServices.indexOf(service) > -1
                            }
                            onChange={() => updateSelectedServices(service)}
                            widths={1}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </GridColumn>
                </GridRow>

                <Divider section />

                <GridRow>
                  <GridColumn width={16}>
                    <span className="field-label">DBS File</span>
                    <Form.Group>
                      <Form.Field width={4} className="pr-15">
                        <Button
                          as="label"
                          htmlFor="file"
                          type="button"
                          className="file-uploader mb-0"
                        >
                          <Button.Content>
                            <Icon name="file" />
                            Choose a File
                          </Button.Content>
                        </Button>
                        <input
                          type="file"
                          id="file"
                          multiple
                          hidden
                          onChange={fileChange}
                        />
                      </Form.Field>
                      <Form.Field width={10} className="align-self-center">
                        {dbsFileNames?.length > 0 ? (
                          <ul className="pl-30">
                            {dbsFileNames?.map((fileName, idx) => (
                              <li key={idx}>
                                <a
                                  href="#"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    const signedUrl = await getSignedUrls(
                                      fileName
                                    );
                                    if (signedUrl) {
                                      window.open(
                                        signedUrl,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }
                                  }}
                                >
                                  {fileName}
                                </a>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <h4 className="text-danger">File not attached</h4>
                        )}
                      </Form.Field>
                    </Form.Group>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          </div>

          {/* Gallery */}
          <div className="card-item mt-20">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="camera" className="mr-8" />
                Gallery
              </h3>
              <Button
                color={
                  maid.gallery !== JSON.stringify(imageGallery) &&
                  maid.gallery &&
                  !isCallingAPI
                    ? "green"
                    : ""
                }
                position="right"
                onClick={() => {
                  !isCallingAPI && save("gallery");
                }}
                disabled={
                  !maid.gallery ||
                  maid.gallery === JSON.stringify(imageGallery) ||
                  isCallingAPI
                }
                loading={isCallingAPI}
              >
                Save changes
              </Button>
            </Item>

            <div className="imageContainer">
              {imageGallery?.length > 0 ? (
                imageGallery.map((im, i) => (
                  <div key={i} className="imageWrapper">
                    <Image
                      src={im.uri}
                      width="133"
                      height="100"
                      className="imageItem"
                    />
                    <Button
                      size="mini"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteImage(i);
                      }}
                    >
                      Delete image
                    </Button>
                  </div>
                ))
              ) : (
                <h4 className="text-danger">Not any images found</h4>
              )}
            </div>
          </div>

          {/* Reviews */}
          <div className="card-item mt-20">
            <Item className="ui horizontal justify-content-between statistic w-100 mb-20">
              <h3 className="mb-0 font-20">
                <Icon name="star" className="mr-8" />
                Reviews
              </h3>

              <div>
                {/* <Button
                  type="button"
                  secondary
                  onClick={handleConfirmSendMail}
                  disabled={oneTimeMailSend}
                >
                  Testimonial Request Email
                </Button>
                {openMailConfirm} */}

                <Button color="green" onClick={() => setIsReviewModal(true)}>
                  Add Review
                </Button>
              </div>
            </Item>

            <div className="review-list">
              {maidReviewsData.length > 0 ? (
                maidReviewsData?.map((reviewDetails, index) => {
                  return (
                    <div key={index} className="card-item ">
                      <Item className="ui horizontal justify-content-between statistic w-100">
                        <div className="d-flex flex-column comment-user">
                          <div className="d-flex mb-16">
                            <a
                              className="mb-0"
                              href={
                                reviewDetails?.user?.userType === "Customers"
                                  ? `/customer/${reviewDetails.user.cognitoId}`
                                  : ""
                              }
                              target="_blank"
                            >
                              {reviewDetails?.authorName ??
                                `${reviewDetails?.user?.firstName ??
                                  "Admin"} ${reviewDetails?.user?.lastName ??
                                  ""}`}
                            </a>
                            {/* <span>from London</span> */}
                          </div>
                          <Rating
                            emptySymbol={<i className="icon star outline"></i>}
                            fullSymbol={<i className="icon star"></i>}
                            fractions={2}
                            initialRating={reviewDetails?.rating}
                            readonly
                            className="rating-star"
                          />
                        </div>

                        <Button
                          primary
                          onClick={() =>
                            handleFetchReviewDataByIndex(reviewDetails)
                          }
                        >
                          Edit review
                        </Button>
                      </Item>

                      <div className="review-comment">
                        <p>{reviewDetails?.content}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h4 className="text-danger">Not any reviews found</h4>
              )}
            </div>
          </div>
        </GridColumn>

        <GridColumn width={RIGHT_WIDTH}>
          <div className="card-item">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="conversation" className="mr-8" />
                Conversations
              </h3>
              <Button
                primary
                position="right"
                as={Link}
                to={`/conversation/${id}?s=1&url=/maid/${id}&name=Maid`}
              >
                Message Maid
              </Button>
            </Item>

            <ConversationsListV2 maidId={id} />
          </div>
        </GridColumn>
      </Grid.Row>

      {/* Modal */}
      <Modal
        closeIcon
        size="tiny"
        className="review-modal"
        onClose={() => handleCloseReviewModal()}
        onOpen={() => setIsReviewModal(true)}
        open={isReviewModal}
      >
        <ModalHeader>{reviewId ? "Edit Review" : "Add Review"}</ModalHeader>
        <ModalContent>
          <Form>
            <Grid>
              <GridRow>
                <GridColumn width={8}>
                  <Form.Input
                    fluid
                    label="Author"
                    placeholder="Author Name"
                    value={authorName}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setAuthorName(data?.value);
                      setErrors({
                        ...errors,
                        authorName: data?.value ? false : true,
                      });
                    }}
                    disabled={isAuthorNameEdit}
                  />
                  {errors.authorName && (
                    <span class="form-group-error">
                      Author name is required
                    </span>
                  )}
                </GridColumn>

                <GridColumn width={8}>
                  <Form.Select
                    fluid
                    label="Rating"
                    options={ratingOptions}
                    placeholder="Rating"
                    defaultValue={rating}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setRating(data?.value);
                      setErrors({
                        ...errors,
                        rating: data?.value ? false : true,
                      });
                    }}
                  />
                  {errors.rating && (
                    <span class="form-group-error">Select rating</span>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>

            <Grid>
              <GridRow>
                <GridColumn width={16}>
                  <Form.Field
                    rows={7}
                    control={TextArea}
                    label="Message"
                    placeholder="Type your message..."
                    value={messageContent}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setMessageContent(data?.value);
                      setErrors({
                        ...errors,
                        content: data?.value ? false : true,
                      });
                    }}
                  />
                  {errors.content && (
                    <span className="form-group-error">
                      Review content is required
                    </span>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>
          </Form>
        </ModalContent>

        <ModalActions
          className={`d-flex ${
            reviewId ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {reviewId && (
            <Button
              basic
              color="red"
              onClick={() => setIsDeleteReviewModal(true)}
            >
              <Icon name="trash alternate" /> Delete this review
            </Button>
          )}
          <Button
            color="green"
            onClick={() => {
              !isCallingAPI &&
                setErrors({
                  authorName: authorName ? false : true,
                  rating: rating ? false : true,
                  content: messageContent ? false : true,
                });
              reviewId ? handleUpdateReview() : handleAddMaidRevies();
            }}
            loading={isCallingAPI}
          >
            {reviewId ? "Save changes" : "Add review"}
          </Button>
        </ModalActions>

        <Modal
          // closeIcon
          size="mini"
          className="review-modal confirm-modal"
          onClose={() => setIsDeleteReviewModal(false)}
          open={isDeleteReviewModal}
        >
          <ModalContent>
            <div className="confirm-content">
              <p>
                {`Are you sure you want to delete this review by ‘${authorName ??
                  "customer"}’ ?`}
              </p>
            </div>
          </ModalContent>
          <ModalActions className="d-flex justify-content-center">
            <Button secondary onClick={() => setIsDeleteReviewModal(false)}>
              Cancel
            </Button>
            <Button color="red" onClick={() => handleDeleteReview()}>
              Yes, delete it
            </Button>
          </ModalActions>
        </Modal>
      </Modal>
    </>
  );
};

export default EditMaid;
