/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  Divider,
  Header,
  Icon,
  Form,
  Message,
  Button,
  Item,
  FormGroup,
  GridRow,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Checkbox,
  Modal,
  ModalContent,
  ModalActions,
  HeaderContent,
  HeaderSubheader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ConversationsList from "../../molecules/ConversationsList";
import Notifications from "../Notifications";
import {
  updateUserStatus,
  updateUser,
  closeOpenSearches,
  deleteReview,
  updateOpenSearchReceiveProposal,
} from "../../../graphql/mutations";
import { disableUser } from "../../../services/AppData";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import cleaningServices from "../../../constants/cleaningServices";
import preferredDays from "../../../constants/PreferredDays";
import {
  customerPostedReviews,
} from "../../../graphql/queries";
import { API, Auth, graphqlOperation } from "aws-amplify";
import "./style.css";
import Rating from "react-rating";
import { BreadcrumbExampleShorthand } from "../../molecules/functions";
import { API_GATEWAY } from "../../../constants/Urls";
import axios from "axios";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const EditCustomer = ({ id, customer, customerHeaderData, location }) => {
  const [callingAPI, setCallingAPI] = useState(false);
  const [pageMessage, setPageMessage] = useState({
    type: "none",
    header: "",
    message: "",
  });

  const [updatedCustomer, setUpdatedCustomer] = useState(customer);
  const [
    mutateCustomer,
    { loading: cusMutationLoading, error: cusMutationError },
  ] = useMutation(gql(updateUser));

  const [
    mutateCustomerStatus,
    { loading: cusStatusMutationLoading, error: cusStatusMutationError },
  ] = useMutation(gql(updateUserStatus));

  const [
    closeOpenSearchesMutation,
    {
      loading: closeOpenSearchesMutationLoading,
      error: closeOpenSearchesMutationError,
    },
  ] = useMutation(gql(closeOpenSearches));
  const [
    updateOpenSearchReceiveProposalMutation,
    {
      loading: updateOpenSearchReceiveProposalMutationLoading,
      error: updateOpenSearchReceiveProposalMutationError,
    },
  ] = useMutation(gql(updateOpenSearchReceiveProposal));

  const [
    deleteMaidReviewsbyAdmin,
    {
      loading: deleteReviewsMutationLoading,
      error: deleteReviewsMutationError,
    },
  ] = useMutation(gql(deleteReview));

  const [formSuccess, setFormSuccess] = useState(false);
  const [isErrors, setIsErrros] = useState(false);
  const [isProfileEqual, setIsProfileEqual] = useState(true);
  const [accountStatus, setAccountStatus] = useState(customer?.status);

  const [activeIndex, setActiveIndex] = useState(0);
  const [openSearchesDetails, setOpenSearchesDetails] = useState([]);
  const isCloseSearchPopupInitialValues = {
    userId: "",
    searchSerial: null,
    isCloseSearchPopup: false,
  };

  const [isCloseSearchPopup, setIsCloseSearchPopup] = useState(
    isCloseSearchPopupInitialValues
  );

  const [maidReviewsData, setMaidReviewsData] = useState([]);
  const [isDeleteReviewModal, setIsDeleteReviewModal] = useState(false);
  const [selectedProposalValues, setSelectedProposalValues] = useState({});

  const accordionRefs = useRef([]);

  const { cognitoId, firstName, lastName, email, phone } = updatedCustomer;

  const conversations =
    customer &&
    customer.conversations &&
    customer.conversations.userConversations
      ? customer.conversations.userConversations
      : [];

  const handleSaveAdminSection = async () => {
    try {
      const response = await mutateCustomerStatus({
        variables: {
          userId: cognitoId,
          status: accountStatus,
        },
      });
      if (response.data.updateUserStatus.success) {
        setFormSuccess(true);
        setTimeout(() => {
          setFormSuccess(false);
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      console.log("mutateCustomerStatus error", error);
    }
  };

  const handleSaveCusProfile = async () => {
    if (updatedCustomer.firstName !== "" && updatedCustomer.lastName !== "") {
      await mutateCustomer({
        variables: {
          input: {
            cognitoId,
            firstName: updatedCustomer.firstName,
            lastName: updatedCustomer.lastName,
            email: updatedCustomer.email,
          },
        },
      });
      setFormSuccess(true);
      setTimeout(() => {
        setFormSuccess(false);
        window.location.reload();
      }, 5000);
    } else {
      setIsErrros(true);
    }
  };

  // const UserMessage = ({ pPageMessage }) => {
  // 	const [show, setShow] = useState(true);
  // 	useEffect(() => {
  // 		const timeId = setTimeout(() => {
  // 			setPageMessage("");
  // 			setShow(false);
  // 		}, 5000);

  // 		return () => {
  // 			clearTimeout(timeId);
  // 		};
  // 	}, []);

  // 	if (!show) {
  // 		return null;
  // 	}
  // 	if (pPageMessage.type === "success") {
  // 		return (
  // 			<Message
  // 				success
  // 				header={pPageMessage.header}
  // 				content={pPageMessage.message}
  // 			/>
  // 		);
  // 	}
  // 	if (pPageMessage.type === "negative") {
  // 		return (
  // 			<Message
  // 				negative
  // 				header={pPageMessage.header}
  // 				content={pPageMessage.message}
  // 			/>
  // 		);
  // 	}
  // 	return null;
  // };

  // const blockCustomer = (cusrtomerId) => {
  // 	const confirmBox = window.confirm(
  // 		"Do you really want to block this customer ?"
  // 	);
  // 	if (confirmBox === true) {
  // 		setCallingAPI(true);
  // 		const requestBody = { username: cusrtomerId };

  // 		disableUser(JSON.stringify(requestBody))
  // 			.then((resData) => {
  // 				if (resData && resData.status && resData.status === "OK") {
  // 					if (resData.response && resData.response.data) {
  // 						if (resData.response.data.result === "SUCCESS") {
  // 							setPageMessage({
  // 								type: "success",
  // 								header: "Success",
  // 								message: "User disabled successfully",
  // 							});
  // 						} else if (resData.response.data.result === "DISABLEDUSER") {
  // 							setPageMessage({
  // 								type: "negative",
  // 								header: "Failed",
  // 								message: "This user is already in disabled status",
  // 							});
  // 						}
  // 					} else {
  // 						setPageMessage({
  // 							type: "negative",
  // 							header: "Failed",
  // 							message: "Failed to disable user",
  // 						});
  // 					}
  // 				} else {
  // 					setPageMessage({
  // 						type: "negative",
  // 						header: "Failed",
  // 						message: "Failed to delete conversation",
  // 					});
  // 				}
  // 				setCallingAPI(false);
  // 			})
  // 			.catch((error) => {
  // 				console.log("Error occured while deleting convesaton: ", error);
  // 				setCallingAPI(false);
  // 			});
  // 	}
  // };
  const accountStatusOptions = [
    { key: "active", text: "ACTIVE", value: "ACTIVE" },
    { key: "blocked", text: "BLOCKED", value: "BLOCKED" },
  ];
  const maidReceivedProposalsOptions = [
    { key: "yes", text: "Yes", value: true },
    { key: "no", text: "No", value: false },
  ];

  const handleGoodJobPopup = () => {
    return (
      <SweetAlert
        success
        title="Good job!"
        onConfirm={() => {
          setFormSuccess(false);
          window.location.reload();
        }}
        customButtons={
          <React.Fragment>
            <Button
              primary
              onClick={() => {
                setFormSuccess(false);
                window.location.reload();
              }}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Customer details updated successfully!
      </SweetAlert>
    );
  };
  const handleErrorPopup = () => {
    return (
      <SweetAlert
        error
        title="Something went wrong!"
        onConfirm={() => {
          setIsErrros(false);
        }}
        customButtons={
          <React.Fragment>
            <Button
              primary
              onClick={() => {
                setIsErrros(false);
              }}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        The fields "Firstname" and "Lastname" are mandatory.
      </SweetAlert>
    );
  };

  const handlerCloseOpenSearch = async () => {
    const response = await closeOpenSearchesMutation({
      variables: {
        userId: isCloseSearchPopup.userId,
        searchSerial: isCloseSearchPopup.searchSerial,
        updatedAt: Date.now(),
        isClose: true,
        closeReason: "Closed by admin",
      },
    });
    if (response?.data?.updateMaidSearches) {
      setIsCloseSearchPopup(isCloseSearchPopupInitialValues);
      fetchCustOpenSearchesData();
      setActiveIndex(0);
    }
  };

  const handleDropdownChange = (index, value) => {
    setSelectedProposalValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handlerUpdateOpenSearchMaidProposal = async (
    userId,
    searchSerial,
    receiveProposals
  ) => {
    const response = await updateOpenSearchReceiveProposalMutation({
      variables: {
        userId,
        searchSerial,
        updatedAt: Date.now(),
        receiveProposals,
      },
    });
    if (response?.data?.updateMaidSearches) {
      fetchCustOpenSearchesData();
    }
  };

  const handleFetchMaidReviews = async () => {
    const response = await API.graphql(
      graphqlOperation(customerPostedReviews, {
        userId: id,
      })
    );
    if (response?.data?.listUsersReviews?.items) {
      const sortedList = response?.data?.listUsersReviews?.items?.sort(
        (a, b) => {
          if (a?.dateCreated > b?.dateCreated) {
            return -1;
          }
          return 1;
        }
      );
      setMaidReviewsData(sortedList);
    } else {
      setMaidReviewsData([]);
    }
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const response = await deleteMaidReviewsbyAdmin({
        variables: { id: reviewId },
      });
      if (response?.data?.deleteUsersReviews) {
        handleFetchMaidReviews();
        setIsDeleteReviewModal(false);
      }
    } catch (error) {
      console.log("deleteMaidReviewsbyAdmin error", error);
    }
  };

  useEffect(() => {
    // Compare two objects
    const notConsiderKeys = ["conversations", "dateCreated"];
    const prevCustomerDetails = _.omit(customer, notConsiderKeys);
    const updatedCustomerDetails = _.omit(updatedCustomer, notConsiderKeys);
    const isMatchedProfileSection =
      JSON.stringify(prevCustomerDetails) ===
      JSON.stringify(updatedCustomerDetails);
    setIsProfileEqual(isMatchedProfileSection);
  }, [updatedCustomer]);

  const fetchCustOpenSearchesData = async () => {
    const apiURL = `${API_GATEWAY}prod/users/get-maid-searches?userId=${id}`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "GET",
      url: apiURL,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
    })
      .then((res) => {
        console.log("RES___", res);
        setOpenSearchesDetails(res?.data?.data || []);
      })
      .catch((error) => {
        console.log("fetchCustOpenSearchesData: ", error);
      });
  };

  useEffect(() => {
    fetchCustOpenSearchesData();
    handleFetchMaidReviews();
  }, []);

  useEffect(() => {
    if (activeIndex !== -1 && accordionRefs.current[activeIndex]) {
      const element = accordionRefs.current[activeIndex];
      if (element) {
        const rect = element.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top - 100, // Adjust 100 to the desired offset from the top
          behavior: "smooth",
        });
      }
    }
  }, [activeIndex]);

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: location?.state?.prevPathName ?? "customers",
      content: location?.state?.prevPathName ?? "Customers",
      link: true,
      path: location?.state?.prevPathUrl ?? "/customers",
    },
    {
      key: "customer",
      content: "Customer",
      active: true,
    },
  ];

  return (
    <>
      <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
      <Grid.Row>
        <GridColumn width="16" className="header-section">
          <div className="d-flex-center justify-content-between card-item user-info">
            <div className="d-flex-center user-detail">
              <div className="user-name">
                <h2 className="user-name-heading">
                  {customer.firstName} {customer.lastName}
                </h2>
                <div style={{ paddingBottom: "5px" }}>
                  <span>{customer.email}</span>
                </div>
                <span>{customer.phone}</span>
              </div>
            </div>

            <ul className="user-detail-list">
              <li>
                <span>Maids Contacted:</span>
                <h5>
                  {customer?.conversations?.userConversations?.length || 0}
                </h5>
              </li>
              <li>
                <span>Someone’s client:</span>
                <h5>{customerHeaderData?.contacts > 0 ? "Yes" : "No"}</h5>
              </li>
              <li>
                <span>Account status:</span>
                <h5>
                  {customer?.status
                    ? customer?.status?.charAt(0)?.toUpperCase() +
                      customer?.status?.slice(1)?.toLowerCase()
                    : "-"}
                </h5>
              </li>
              <li>
                <span>Last active:</span>
                <h5>
                  {customerHeaderData?.lastActive ? (
                    <ReactTimeAgo
                      date={new Date(Number(customerHeaderData?.lastActive))}
                      locale="en-US"
                    />
                  ) : (
                    "Ancient history"
                  )}
                </h5>
              </li>
              <li>
                <span>Notifications:</span>
                <Notifications userId={id} />
              </li>
            </ul>
          </div>
        </GridColumn>

        <GridColumn width={10} className="left-side">
          {/* ADMIN SECTION */}
          <div className="card-item mb-20">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="cog" className="mr-8" />
                Admin
              </h3>

              <Button
                color={customer.status !== accountStatus ? "green" : ""}
                position="right"
                onClick={() => handleSaveAdminSection()}
                disabled={customer.status === accountStatus}
              >
                Save changes
              </Button>
            </Item>

            <Form>
              <Grid columns="equal">
                <GridRow>
                  <GridColumn width={8}>
                    <FormGroup widths="equal">
                      <Form.Select
                        fluid
                        label="Account Status"
                        options={accountStatusOptions}
                        placeholder="Status"
                        defaultValue={accountStatus}
                        onChange={(e, data) => {
                          setAccountStatus(data.value);
                        }}
                      />
                    </FormGroup>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          </div>

          {/* PROFILE SECTION */}
          <div className="card-item mb-20">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="user" className="mr-8" />
                Profile
              </h3>

              <Button
                color={!isProfileEqual ? "green" : ""}
                position="right"
                onClick={() => handleSaveCusProfile()}
                disabled={isProfileEqual}
              >
                Save changes
              </Button>
            </Item>

            <Form>
              <Grid>
                <GridRow>
                  <GridColumn width={16}>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        required
                        label="First name"
                        placeholder="First name"
                        value={firstName}
                        onChange={(_e, data) => {
                          setUpdatedCustomer({
                            ...updatedCustomer,
                            firstName: data.value,
                          });
                        }}
                      />
                      <Form.Input
                        fluid
                        required
                        className="mb-16"
                        label="Last name"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(_e, data) => {
                          setUpdatedCustomer({
                            ...updatedCustomer,
                            lastName: data.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </GridColumn>

                  <GridColumn width={16}>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(_e, data) => {
                          setUpdatedCustomer({
                            ...updatedCustomer,
                            email: data.value,
                          });
                        }}
                      />
                      <Form.Input
                        fluid
                        label="Phone"
                        placeholder="Phone"
                        value={phone}
                        readOnly
                      />
                    </Form.Group>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          </div>
          {/* OPEN SEARCH SECTION */}
          <div className="card-item mb-20">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-20 font-20">
                <Icon name="search" className="mr-8 p-0" />
                Open Search
              </h3>
            </Item>
            {openSearchesDetails?.length <= 0 && (
              <h4 className="text-danger">No matching records found</h4>
            )}
            <div>
              {openSearchesDetails?.map((custSearchesData, index) => {
                const maidProposalValue =
                  selectedProposalValues[index] ?? custSearchesData?.receiveProposals;
                return (
                  <div
                    className="custom-accowrap"
                    ref={(el) => (accordionRefs.current[index] = el)}
                    key={index}
                  >
                    <Accordion key={index} className="custom-accordian">
                      <AccordionTitle
                        active={activeIndex === index}
                        index={index}
                        onClick={() => {
                          setActiveIndex(activeIndex === index ? -1 : index);
                        }}
                      >
                        {`Search #${index + 1}`}
                        <Icon name="dropdown" />
                      </AccordionTitle>
                      <AccordionContent
                        className="mt-20"
                        active={activeIndex === index}
                      >
                        <Form>
                          <Grid columns="equal">
                            <GridRow className="align-items-end">
                              <GridColumn width={16} className="mb-16">
                                <FormGroup>
                                  <Form.Select
                                    width={8}
                                    label="Receive proposals from Maids"
                                    options={maidReceivedProposalsOptions}
                                    placeholder="Select"
                                    value={maidProposalValue}
                                    onChange={(e, { value }) =>
                                      handleDropdownChange(index, value)
                                    }
                                  />
                                  <div className="button-wrapper">
                                    <Button
                                      className="mt-auto"
                                      width={8}
                                      color={
                                        maidProposalValue ===
                                        custSearchesData?.receiveProposals
                                          ? ""
                                          : "green"
                                      }
                                      onClick={() =>
                                        handlerUpdateOpenSearchMaidProposal(
                                          custSearchesData.userId,
                                          custSearchesData.searchSerial,
                                          selectedProposalValues[index] ??
                                            custSearchesData?.receiveProposals
                                        )
                                      }
                                      disabled={
                                        maidProposalValue ===
                                        custSearchesData?.receiveProposals
                                      }
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </FormGroup>
                              </GridColumn>
                              <Divider section className="w-100" />
                              <GridColumn width={16} className="mb-16">
                                <Form.Group widths="equal">
                                  <Form.Input
                                    className="pointer-none"
                                    fluid
                                    label="Type of service"
                                    value={
                                      Array.isArray(custSearchesData?.cleanType)
                                        ? custSearchesData.cleanType.join(", ")
                                        : custSearchesData?.cleanType || ""
                                    }
                                    readOnly
                                  />
                                  <Form.Input
                                    className="pointer-none"
                                    fluid
                                    label="Frequency of cleaning"
                                    value={custSearchesData?.frequency || ""}
                                    readOnly
                                  />
                                </Form.Group>
                              </GridColumn>

                              <GridColumn
                                width={10}
                                widths="equal"
                                className="mb-16"
                              >
                                <label className="custom-label">
                                  Additional Services
                                </label>
                                <div className="additional-services two-col">
                                  {cleaningServices?.map((service) => (
                                    <Form.Field
                                      className="pointer-none"
                                      key={service}
                                      control={Checkbox}
                                      label={service}
                                      checked={custSearchesData?.services?.includes(
                                        service
                                      )}
                                      widths={1}
                                      readOnly
                                    />
                                  ))}
                                </div>
                              </GridColumn>

                              <GridColumn
                                width={6}
                                widths="equal"
                                className="mb-16"
                              >
                                <label className="custom-label">
                                  Preferred days
                                </label>
                                <div className="">
                                  {preferredDays?.map((day) => (
                                    <Form.Field
                                      className="pointer-none"
                                      key={day}
                                      control={Checkbox}
                                      label={day}
                                      widths={1}
                                      checked={custSearchesData?.days?.includes(
                                        day
                                      )}
                                      readOnly
                                    />
                                  ))}
                                </div>
                              </GridColumn>

                              <GridColumn width={16} className="mb-16 mt-20">
                                <Form.Group widths="equal">
                                  <Form.Input
                                    className="pointer-none"
                                    fluid
                                    label="Property type"
                                    value={custSearchesData?.property || ""}
                                    readOnly
                                  />
                                  <Form.TextArea
                                    fluid
                                    className="text-capitalize pointer-none"
                                    label="Rooms to be cleaned"
                                    value={`${
                                      custSearchesData?.bedroom
                                        ? custSearchesData?.bedroom + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.kitchen
                                        ? custSearchesData?.kitchen + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.bathroom
                                        ? custSearchesData?.bathroom + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.livingroom
                                        ? custSearchesData?.livingroom + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.receptionRooms
                                        ? custSearchesData?.receptionRooms +
                                          "\n"
                                        : ""
                                    }${
                                      custSearchesData?.otherRooms
                                        ? custSearchesData?.otherRooms + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.studyHomeOffice
                                        ? custSearchesData?.studyHomeOffice +
                                          "\n"
                                        : ""
                                    }${
                                      custSearchesData?.gardenOutdoor
                                        ? custSearchesData?.gardenOutdoor + "\n"
                                        : ""
                                    }${
                                      custSearchesData?.hallways
                                        ? custSearchesData?.hallways
                                        : ""
                                    }`.replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                    rows={5}
                                    readOnly
                                    disabled
                                  />
                                </Form.Group>
                              </GridColumn>
                              <Divider section className="w-100" />
                              <GridColumn width={16} className="mb-16">
                                <Form>
                                  <Form.Input
                                    className="pointer-none"
                                    width={8}
                                    label="Address line 1"
                                    value={custSearchesData?.address?.line1}
                                    readOnly
                                  />
                                  <Form.Input
                                    className="pointer-none"
                                    width={8}
                                    label="Address line 2"
                                    value={
                                      custSearchesData?.address?.line2 || ""
                                    }
                                    readOnly
                                  />
                                  <FormGroup widths="equal">
                                    <Form.Input
                                      className="pointer-none"
                                      fluid
                                      label="Town"
                                      value={
                                        custSearchesData?.address?.town || ""
                                      }
                                      readOnly
                                    />

                                    <Form.Input
                                      className="pointer-none"
                                      fluid
                                      label="Country"
                                      value={
                                        custSearchesData?.address?.country || ""
                                      }
                                      readOnly
                                    />
                                  </FormGroup>
                                  <Form.Input
                                    className="pointer-none"
                                    width={8}
                                    label="Postcode"
                                    value={
                                      custSearchesData?.address?.postcode || ""
                                    }
                                    readOnly
                                  />
                                </Form>
                              </GridColumn>
                              <Divider section className="w-100" />
                              <GridColumn width={16} className="mb-16">
                                <Form.TextArea
                                  className="pointer-none"
                                  fluid
                                  label="Anything else we missed?"
                                  value={
                                    custSearchesData?.additionalJobInfo || ""
                                  }
                                  readOnly
                                />
                              </GridColumn>

                              <GridColumn>
                                <Button
                                  color="red"
                                  className="mb-16 mt-10"
                                  onClick={() => {
                                    setIsCloseSearchPopup(() => {
                                      return {
                                        userId: custSearchesData?.userId,
                                        searchSerial:
                                          custSearchesData?.searchSerial,
                                        isCloseSearchPopup: true,
                                      };
                                    });
                                  }}
                                >
                                  Close down this search
                                </Button>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </Form>
                      </AccordionContent>
                    </Accordion>
                    {/* <Divider section className="w-100" /> */}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Reviews */}
          <div className="card-item mt-20">
            <Item className="ui horizontal justify-content-between statistic w-100 mb-20">
              <Header as="h3" className="">
                <Icon name="star" className="mr-8" />
                <HeaderContent>
                  Reviews
                  <HeaderSubheader>
                    Displaying reviews about the maids
                  </HeaderSubheader>
                </HeaderContent>
              </Header>
            </Item>
            <div className="review-list">
              {maidReviewsData.length > 0 ? (
                maidReviewsData?.map((reviewDetails, index) => {
                  return (
                    <div key={index} className="card-item ">
                      <Item className="ui horizontal justify-content-between statistic w-100">
                        <div className="d-flex flex-column comment-user">
                          <div className="d-flex mb-16">
                            <a
                              className="mb-0"
                              href={
                                reviewDetails?.maid
                                  ? `/maid/${reviewDetails.maid.userId}`
                                  : ""
                              }
                              target="_blank"
                            >
                              {reviewDetails?.maid?.fullName ??
                                `${reviewDetails?.authorName ?? "Admin"}`}
                            </a>
                            {/* <span>from London</span> */}
                          </div>
                          <Rating
                            emptySymbol={<i className="icon star outline"></i>}
                            fullSymbol={<i className="icon star"></i>}
                            fractions={2}
                            initialRating={reviewDetails?.rating}
                            readonly
                            className="rating-star"
                          />
                        </div>

                        <Button
                          color="red"
                          onClick={() =>
                            setIsDeleteReviewModal(reviewDetails?.id)
                          }
                        >
                          Delete
                        </Button>
                      </Item>

                      <div className="review-comment">
                        <p>{reviewDetails?.content}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h4 className="text-danger">Not any reviews found</h4>
              )}
            </div>
          </div>
        </GridColumn>

        <GridColumn width={6} className="right-side">
          <div className="card-item">
            <Item className="ui horizontal justify-content-between statistic w-100">
              <h3 className="mb-0 font-20">
                <Icon name="conversation" className="mr-8" />
                Conversations
              </h3>
              <Button
                primary
                position="right"
                as={Link}
                to={`/conversation/${id}?s=1&url=/customer/${id}&name=Customer`}
              >
                Message Customer
              </Button>
            </Item>

            <ConversationsList conversations={conversations} customer={id} />
          </div>
        </GridColumn>

        {formSuccess && handleGoodJobPopup()}
        {isErrors && handleErrorPopup()}
        <Modal
          closeIcon
          size="mini"
          className="review-modal confirm-modal"
          onClose={() => setIsCloseSearchPopup(isCloseSearchPopupInitialValues)}
          open={isCloseSearchPopup.isCloseSearchPopup}
        >
          <ModalContent>
            <div className="confirm-content">
              <p>Are you sure you want to close this search ?</p>
            </div>
          </ModalContent>
          <ModalActions className="d-flex justify-content-center">
            <Button
              secondary
              onClick={() =>
                setIsCloseSearchPopup(isCloseSearchPopupInitialValues)
              }
            >
              Cancel
            </Button>
            <Button color="red" onClick={() => handlerCloseOpenSearch()}>
              Yes, delete it
            </Button>
          </ModalActions>
        </Modal>

        <Modal
          // closeIcon
          size="mini"
          className="review-modal confirm-modal"
          onClose={() => setIsDeleteReviewModal(false)}
          open={isDeleteReviewModal}
        >
          <ModalContent>
            <div className="confirm-content">
              <p>Are you sure you want to delete this review ?</p>
            </div>
          </ModalContent>
          <ModalActions className="d-flex justify-content-center">
            <Button secondary onClick={() => setIsDeleteReviewModal(false)}>
              Cancel
            </Button>
            <Button
              color="red"
              onClick={() => handleDeleteReview(isDeleteReviewModal)}
            >
              Yes, delete it
            </Button>
          </ModalActions>
        </Modal>
      </Grid.Row>
    </>
  );
};
export default EditCustomer;
