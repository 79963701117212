import React, { useEffect } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { useMutation } from "@apollo/react-hooks";
import ScrollToBottom from "react-scroll-to-bottom";
import gql from "graphql-tag";
import Avatar from "react-avatar";
import { v4 as uuidv4 } from "uuid";
import { graphqlOperation } from "aws-amplify";
import { Divider, Header, Icon, Image, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import ChatInput from "../../components/atoms/ChatInput";
import ConversationParticipants from "../../components/organisms/ConversationParticipants";
import BaseLayout from "../../components/templates/BaseLayout";
import { allMessageConnection } from "../../graphql/queries";
import { subscribeToNewMessage } from "../../graphql/subscriptions";
import {
  createMessage,
  addSupportConversation,
  createUserConversations,
} from "../../graphql/mutations";
import "./styles.css";
import {
  BreadcrumbExampleShorthand,
  getSessionStoreValue,
} from "../../components/molecules/functions";

const moment = require("moment");

const CREATE_MESSAGE = gql(createMessage);
const ADD_SUPPORT_CONVERSATION = gql(addSupportConversation);
const CREATE_USER_CONVERSATION = gql(createUserConversations);

const ConversationDetails = ({ conversationId }) => {
  const ConversationView = ({ messages }) => {
    return (
      <ScrollToBottom className="messageList">
        {messages.map((message) => {
          const {
            id,
            content,
            image,
            author,
            createdAt,
            sender: senderId,
          } = message;
          const { userType, firstName, lastName } = author;
          const messageDate = moment(createdAt, "x").format(
            "DD ddd MMM YYYY HH:mm"
          );

          return (
            <li key={id}>
              <div>
                <Avatar
                  name={`${firstName} ${lastName}`}
                  size={30}
                  round="15px"
                  style={{ float: "left", marginRight: "12px" }}
                />
              </div>

              <div>
                <div>
                  {" "}
                  <a
                    className="messageLink"
                    href={
                      userType === "Customers"
                        ? `/customer/${senderId}`
                        : `/maid/${senderId}`
                    }
                  >
                    {firstName} {lastName}
                  </a>
                  <span className="messageTime">{messageDate}</span>
                </div>
                <div>
                  {content && <span>{content}</span>}
                  {image && <Image src={image} size="small" />}
                </div>
              </div>
            </li>
          );
        })}
      </ScrollToBottom>
    );
  };

  return (
    <Connect
      query={graphqlOperation(allMessageConnection, { conversationId })}
      subscription={graphqlOperation(subscribeToNewMessage, { conversationId })}
      onSubscriptionMsg={(prev, { subscribeToNewMessage: newMessage }) => {
        prev.allMessageConnection.messages.push(newMessage);

        return prev;
      }}
    >
      {({ data: { allMessageConnection }, loading }) => {
        if (loading || !allMessageConnection)
          return (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                Processing your request.
              </Message.Content>
            </Message>
          );

        const sortedMessages = allMessageConnection.messages.sort(
          (a, b) => a.createdAt - b.createdAt
        );

        return <ConversationView messages={sortedMessages} />;
      }}
    </Connect>
  );
};

const Conversation = ({ location }) => {
  const prevNavigation = getSessionStoreValue({ location });
  const { id } = useParams();
  const isSupport = location.search === "?s=1";
  const [addMessage] = useMutation(CREATE_MESSAGE);
  const [createSupportConversation] = useMutation(ADD_SUPPORT_CONVERSATION);
  const [addUserConversation] = useMutation(CREATE_USER_CONVERSATION);

  useEffect(() => {
    if (isSupport) {
      createSupportConversation({
        variables: {
          input: {
            id,
            createdAt: Date.now(),
            name: id,
          },
        },
      });
      addUserConversation({
        variables: {
          conversationId: id,
          userId: id,
          isSupport: true,
        },
      });
    }
  }, [createSupportConversation, addUserConversation, id, isSupport]);

  const onKeyDown = async (message) => {
    const result = await addMessage({
      variables: {
        content: message,
        createdAt: Date.now(),
        conversationId: id,
        id: uuidv4(),
      },
    });

    return result.data.createMessage;
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    prevNavigation?.state?.prevPathName === "Customer" && {
      key: "customers",
      content: "Customers",
      link: true,
      path: "/customers",
    },
    prevNavigation?.state?.prevPathName === "Maid" && {
      key: "maids",
      content: "Maids",
      link: true,
      path: "/maids",
    },
    prevNavigation?.state?.prevPathName && {
      key: prevNavigation?.state?.prevPathName,
      content: prevNavigation?.state?.prevPathName,
      link: true,
      path: prevNavigation?.state?.prevPathUrl,
    },
    {
      key: "conversations",
      content: "Conversations",
      active: true,
    },
  ];

  return (
    <BaseLayout>
      <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="conversation" />
          Conversation
        </Header>
      </Divider>
      <ConversationParticipants conversationId={id} />
      <ConversationDetails conversationId={id} />
      <ChatInput onKeyDown={(message) => onKeyDown(message)} />
    </BaseLayout>
  );
};

export default withAuthenticator(Conversation);
